import FlexCentered from "../../Containers/FlexCentered";
import Page from "../../Containers/Page";
import RotaView from "../../sections/RotaView";
import { useGetRotaReport } from "../../api";
import getApiStatusPage from "../APIStatusPage";
import P from "../../Components/P";

function AdminDash() {
  const getRotaReportApi = useGetRotaReport();
  const rotaReport = getRotaReportApi?.data?.report;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getRotaReportApi,
        type: "Rota report",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Admin - Dashboard">
      <FlexCentered col>
        <div className="mt-4 hidden md:block">
          <RotaView rota={rotaReport} />
        </div>
        <div className="mt-4 md:hidden mt-8">
          <FlexCentered col>
            <P className="max-w-sm mb-4">Welcome to the admin dashboard.</P>
            <P className="max-w-sm">
              The rota view is not available on small screens.
            </P>
          </FlexCentered>
        </div>
      </FlexCentered>
    </Page>
  );
}

export default AdminDash;
