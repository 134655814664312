const landNouns = [
  "Accentor",
  "Babbler",
  "Bittern",
  "Blackbird",
  "Booby",
  "Bullfinch",
  "Bunting",
  "Buzzard",
  "Cassowary",
  "Chat",
  "Crake",
  "Crane",
  "Chiffchaff",
  "Coot",
  "Cormorant",
  "Crow",
  "Cuckoo",
  "Curlew",
  "Dipper",
  "Dodo",
  "Dove",
  "Dunnock",
  "Eagle",
  "Emu",
  "Egret",
  "Falcon",
  "Finch",
  "Fieldfare",
  "Flycatcher",
  "Gannet",
  "Goose",
  "Goshawk",
  "Harrier",
  "Hawk",
  "Heron",
  "Hobby",
  "Hoopee",
  "Hornbill",
  "Hornbill",
  "Jay",
  "Kestrel",
  "Kingfisher",
  "Kite",
  "Lapwing",
  "Lark",
  "Linnet",
  "Magpie",
  "Martin",
  "Merlin",
  "Nightjar",
  "Nuthatch",
  "Osprey",
  "Owl",
  "Owlet",
  "Oystercatcher",
  "Petrel",
  "Piculet",
  "Pipit",
  "Plover",
  "Raven",
  "Redstart",
  "Robin",
  "Sandpiper",
  "Shrike",
  "Siskin",
  "Snipe",
  "Sparrow",
  "Sparrowhawk",
  "Starling",
  "Stork",
  "Snipe",
  "Swallow",
  "Swift",
  "Tern",
  "Thrush",
  "Tit",
  "Vulture",
  "Wagtail",
  "Warbler",
  "Waxbill",
  "Widgeon",
  "Woodpecker",
  "Wren",
  "Wryneck",
];

export default landNouns;
