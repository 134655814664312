import { useGetCurrentUser } from "../api/index";
import { Routes, Route } from "react-router-dom";
import Navigation from "../sections/Navigation";
import AdminDash from "../Pages/admin/AdminDash";
import AuthRoutes from "./AuthRoutes";
import ConfirmEmail from "../Pages/adminAuth/ConfirmEmail";
import Profile from "../Pages/admin/Profile";
import NotFound from "../Pages/NotFound";
import ViewRotaPage from "../Pages/admin/Rota/ViewRotaPage";
import CreateRotaPage from "../Pages/admin/Rota/CreateRotaPage";
import EditRotaPage from "../Pages/admin/Rota/EditRotaPage";
import EditShiftsPage from "../Pages/admin/Rota/EditShiftsPage";
import UsersPage from "../Pages/admin/Users/UsersPage";
import AddUserPage from "../Pages/admin/Users/AddUserPage";
import UserPage from "../Pages/admin/Users/UserPage";
import EditUserPage from "../Pages/admin/Users/EditUserPage";
import ReportsPage from "../Pages/admin/Reports";

function AdminRouter() {
  const currentUserAPI = useGetCurrentUser();

  const currentUser = currentUserAPI.data;
  if (!currentUser) return <div>Loading</div>;

  if (!currentUser.isLoggedIn || !currentUser.isAdmin) return <AuthRoutes />;
  if (!currentUser.emailConfirmed)
    return (
      <>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<ConfirmEmail />} />
          <Route path="/*" element={<NotFound redirect="/" />} />
        </Routes>
      </>
    );

  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<AdminDash />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/rota" element={<ViewRotaPage />} />
        <Route exact path="/rota/create-rota" element={<CreateRotaPage />} />
        <Route exact path="/rota/edit-rota" element={<EditRotaPage />} />
        <Route exact path="/rota/edit-shifts" element={<EditShiftsPage />} />
        <Route exact path="/users" element={<UsersPage />} />
        <Route exact path="/users/add-user" element={<AddUserPage />} />
        <Route exact path="/users/:userRef" element={<UserPage />} />
        <Route exact path="/users/:userRef/edit" element={<EditUserPage />} />
        <Route exact path="/reports" element={<ReportsPage />} />
        <Route path="/*" element={<NotFound redirect="/" />} />
      </Routes>
    </>
  );
}

export default AdminRouter;
