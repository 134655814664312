import { useGetActiveRota } from "../../../api";
import Button from "../../../Components/Button";
import Link from "../../../Components/Link";
import Page from "../../../Containers/Page";
import getApiStatusPage from "../../APIStatusPage";
import P from "../../../Components/P";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";

function getDate(dateStr) {
  return new Date(dateStr).toLocaleDateString("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });
}

function ViewRotaPage() {
  const getActiveRotaApi = useGetActiveRota();

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getActiveRotaApi,
        type: "Active rota",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const rota = getActiveRotaApi?.data?.rota;

  let content;

  if (!rota) {
    content = (
      <div className="pt-8">
        <FlexCentered col>
          <Heading primary className="mb-4">
            Active Rota
          </Heading>
          <P>No active rota.</P>
          <Link to="/admin/rota/create-rota">
            <Button primary className="mt-4">
              Create Rota
            </Button>
          </Link>
        </FlexCentered>
      </div>
    );
  } else {
    content = (
      <div className="pt-8">
        <FlexCentered col>
          <Heading primary className="mb-4">
            Active Rota
          </Heading>
          <Heading secondary className="mb-4">
            {rota.name}
          </Heading>
          <div className="p-2 bg-gbfBlue-500 text-gbfCream-50 mb-4">
            <div className="grid grid-cols-2 gap-y-2">
              <P>Start: </P>
              <P>{getDate(rota.eventStart)}</P>
              <P>End: </P>
              <P>{getDate(rota.eventEnd)}</P>
              <P>Lockdown: </P>
              <P>{getDate(rota.lockdown)}</P>
            </div>
          </div>

          <div className="grid grid-cols-2 w-fit gap-x-2">
            <Link to="/admin/rota/edit-rota">
              <Button primary outline className="mt-4 w-full">
                Edit Overview
              </Button>
            </Link>
            <Link to="/admin/rota/edit-shifts">
              <Button primary outline className="mt-4 w-full">
                Edit Shifts
              </Button>
            </Link>
          </div>
        </FlexCentered>
      </div>
    );
  }

  return <Page title="Rota">{content}</Page>;
}
export default ViewRotaPage;
