import classNames from "classnames";

function Input({ disabled, error, className, innerRef, type, ...props }) {
  const classes = classNames(
    "outline-none focus:ring-transparent active:ring-transparent border ",
    {
      "px-2": !(className || "").includes("px"),
      "py-1": !(className || "").includes("py"),
      "text-center": !(className || "").includes("text"),
      "active:border-red-700 focus:border-red-700 border-red-700": error,
      "active:border-gbfGreen-800 focus:border-gbfGreen-800": !error,
      "bg-gray-200 pointer-events-none": disabled,
    },
    className
  );

  return (
    <input
      className={classes}
      ref={innerRef}
      type={type}
      disabled={disabled}
      {...props}
    />
  );
}

export default Input;
