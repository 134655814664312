function calculateMaxAndMinX(
  earliestDate,
  latestDate,
  blockWidth,
  sideBarWidth
) {
  const firstBlock = earliestDate.earliestBlock;
  const lastBlock = latestDate.lastBlock;
  const totalBlocks = lastBlock - firstBlock;
  const maxVisibleBlocks = Math.ceil(
    (window.innerWidth - sideBarWidth) / blockWidth
  );

  const maxX = Math.max(
    (firstBlock + 1 + totalBlocks - maxVisibleBlocks) * blockWidth,
    (firstBlock - 1) * blockWidth
  );

  const minX = (firstBlock - 1) * blockWidth;

  return [minX, maxX];
}
export default calculateMaxAndMinX;
