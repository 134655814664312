import { BsFiletypeXlsx } from "react-icons/bs";
import { utils, writeFile } from "xlsx";
import { useCallback } from "react";

function JobSheetsXLSX({ report }) {
  const exportFile = useCallback(() => {
    const reportDate = new Date(report.query.day);
    let sortableDate = "";
    if (Number.isFinite(reportDate.getTime())) {
      sortableDate = `_${reportDate.getFullYear()}.${`${
        reportDate.getMonth() + 1
      }`.padStart(2, "0")}.${`${reportDate.getDate()}`.padStart(2, "0")}`;
    }
    const fileName = `job-sheets${sortableDate}_team=${report.query.team}_job=${report.query.job}.pdf`;

    /* create workbook and append worksheet */
    const wb = utils.book_new();

    report.jobSheets.forEach((jobSheet) => {
      const date = new Date(jobSheet.day);
      const shortDate = date
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replaceAll("/", ".");
      const sheetName = `${jobSheet.job.slice(0, 22)}_${shortDate}`;
      const data = [];
      jobSheet.slots.forEach((slot) => {
        if (slot.volunteers.length > 0) {
          slot.volunteers.forEach((vol) => {
            data.push({
              Job: jobSheet.job,
              Day: jobSheet.day,
              Start: slot.start,
              End: slot.end,
              Volunteer: vol,
            });
          });
        } else {
          data.push({
            Job: jobSheet.job,
            Day: jobSheet.day,
            Start: slot.start,
            End: slot.end,
            Volunteer: "EMPTY",
          });
        }
      });
      const ws = utils.json_to_sheet(data);
      utils.book_append_sheet(wb, ws, sheetName);
    });

    // utils.book_append_sheet(wb, ws, "Job Sheet 1");
    /* export to XLSX */
    writeFile(wb, `${fileName}.xlsx`);
  }, [report.jobSheets, report.query.day, report.query.job, report.query.team]);

  return (
    <div className="w-fit bg-gbfCream-50 p-2 rounded-full cursor-pointer text-3xl text-[#217346] hover:scale-105">
      <BsFiletypeXlsx onClick={() => exportFile()} />
    </div>
  );
}
export default JobSheetsXLSX;
