import { useEditTeam, useDeleteTeam } from "../../../../api";
import classNames from "classnames";
import { Fragment, useState, useEffect } from "react";
import {
  BsChevronLeft,
  BsChevronDown,
  BsPencil,
  BsTrash,
  BsXLg,
} from "react-icons/bs";
import P from "../../../../Components/P";
import AddTeam from "../../../../forms/rota/AddTeam";
import Jobs from "./Jobs";
import { useToastContext } from "../../../../providers/toastContext";
import { useEditRotaContext } from "../../../../providers/EditRotaContext";

function Teams({ rota, teams }) {
  const [editing, setIsEditing] = useState(null);
  const [teamName, setTeamName] = useState("null");

  const rotaContext = useEditRotaContext();

  const editTeamApi = useEditTeam();
  const deleteTeamApi = useDeleteTeam();

  const { createErrorToast, createSuccessToast } = useToastContext();
  useEffect(() => {
    if (editTeamApi.error) {
      setIsEditing(null);
      setTeamName("");
      createErrorToast(editTeamApi.error);
      editTeamApi.reset();
    }
  }, [createErrorToast, editTeamApi]);

  useEffect(() => {
    if (editTeamApi.isSuccess) {
      setIsEditing(null);
      setTeamName("");
      editTeamApi.reset();
    }
  }, [editTeamApi]);

  useEffect(() => {
    if (deleteTeamApi?.isSuccess) {
      deleteTeamApi.reset();
      createSuccessToast("Team deleted");
      rotaContext.setIsLoading(false);
    }
  }, [createSuccessToast, deleteTeamApi, rotaContext]);

  useEffect(() => {
    if (deleteTeamApi?.error) {
      createErrorToast(deleteTeamApi?.error);
      deleteTeamApi.reset();
      rotaContext.setIsLoading(false);
    }
  }, [createErrorToast, deleteTeamApi, rotaContext]);

  const handleSubmitEdit = () => {
    editTeamApi.mutate({
      rotaRef: rota?._id,
      teamRef: editing?._id,
      body: {
        name: teamName,
      },
    });
  };

  const handleDelete = (id) => {
    rotaContext.setActiveTeam(null);
    rotaContext.setIsLoading(true);
    deleteTeamApi.mutate({
      rotaRef: rotaContext?.rota,
      teamRef: id,
    });
  };

  let renderedTeams;
  if (teams.length > 0) {
    renderedTeams = teams.map((team) => {
      const isEditing = editing === team;
      const isOpen =
        rotaContext.activeTeam?._id?.toString() === team?._id?.toString();

      return (
        <Fragment key={team._id}>
          <div
            className={classNames(
              "border w-full px-4 py-2 shadow select-none",
              {
                "bg-gbfCream-50": !isOpen,
                "bg-gbfGreen-1000 text-gbfCream-50": isOpen && !isEditing,
                "bg-gbfBlue-200 bg-opacity-50": isEditing,
              }
            )}
          >
            <div className="grid grid-cols-[1fr__auto_auto_auto] gap-x-4 items-center">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitEdit();
                }}
              >
                <input
                  value={
                    !isEditing
                      ? team.name
                      : editTeamApi.isLoading
                      ? "Loading..."
                      : teamName
                  }
                  onChange={(e) => setTeamName(e?.target?.value)}
                  disabled={!isEditing || editTeamApi.isLoading}
                  className={classNames(
                    "w-fit px-2 py-1 rounded ring-transparent border border-transparent outline-none focus:border-gbfGreen-900",
                    {
                      "bg-transparent": !isEditing,
                    }
                  )}
                />
              </form>
              {!isEditing && (
                <BsPencil
                  className={classNames("cursor-pointer", {
                    "hover:text-gbfGreen-900": !isOpen,
                    "hover:text-gbfGreen-300": isOpen,
                  })}
                  onClick={() => {
                    setIsEditing(team);
                    setTeamName(team.name);
                    rotaContext.setActiveTeam(null);
                  }}
                />
              )}
              {isEditing && (
                <BsXLg
                  className="text-red-700 hover:text-red-900 cursor-pointer"
                  onClick={() => {
                    setIsEditing(null);
                    setTeamName(null);
                  }}
                />
              )}
              <BsTrash
                className="cursor-pointer hover:text-red-700"
                onClick={() => handleDelete(team._id)}
              />
              {rotaContext.activeTeam !== team && (
                <BsChevronLeft
                  className="cursor-pointer hover:text-gbfGreen-900"
                  onClick={() => {
                    rotaContext.setActiveTeam((curr) =>
                      curr === team ? null : team
                    );
                    setIsEditing(null);
                    setTeamName(null);
                  }}
                />
              )}
              {rotaContext.activeTeam === team && (
                <BsChevronDown
                  className="cursor-pointer hover:text-gbfGreen-300"
                  onClick={() => {
                    rotaContext.setActiveTeam((curr) =>
                      curr === team ? null : team
                    );
                    rotaContext?.setActiveJob(null);
                    setIsEditing(null);
                    setTeamName(null);
                  }}
                />
              )}
            </div>
          </div>
          {isOpen && (
            <div className="px-1 border py-1 bg-gray-100">
              <Jobs />
            </div>
          )}
        </Fragment>
      );
    });
  } else
    renderedTeams = (
      <div>
        <P>No teams found</P>
      </div>
    );

  return (
    <div className="px-2 max-w-xl w-full">
      {renderedTeams}

      {!rotaContext?.activeTeam && (
        <AddTeam
          onFocus={() => rotaContext.setActiveTeam(null)}
          rota={rota}
          forceOpen={!teams?.length}
        />
      )}
    </div>
  );
}
export default Teams;
