import { useUpdatePassword } from "../api";
import { useForm } from "react-hook-form";
import { useToastContext } from "../providers/toastContext";
import FlexCentered from "../Containers/FlexCentered";
import Input from "../Components/Input";
import Label from "../Components/Label";
import Button from "../Components/Button";
import RenderedErrors from "../Components/RenderedErrors";
import { useEffect } from "react";

function UpdatePasswordForm() {
  const updatePasswordApi = useUpdatePassword();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const passwordProps = register("password", {
    required: "Current password required",
  });
  const newPasswordProps = register("newPassword", {
    required: "Current password required",
    minLength: {
      value: 8,
      message: "Your password must be at least 8 characters long",
    },
    maxLength: {
      value: 36,
      message: "Your password cannot exceed 36 characters",
    },
  });
  const confirmNewPasswordProps = register("confirmPassword", {
    required: "Current password required",

    validate: (value, formValues) => {
      return formValues.newPassword === value || "The passwords do not match";
    },
  });

  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (updatePasswordApi.isSuccess) {
      createSuccessToast("Password updated successfully.");
      form.reset();
      updatePasswordApi.reset();
    }
  }, [createSuccessToast, form, updatePasswordApi]);

  const handleSubmit = (data) => {
    updatePasswordApi.mutate({
      body: data,
    });
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
      <FlexCentered col>
        <Label htmlFor="password" className="mb-2">
          Current Password
        </Label>
        <Input
          type="password"
          id="password"
          className="w-[20rem] max-w-full mb-4"
          {...passwordProps}
          ref={null}
          innerRef={passwordProps.ref}
          error={errors.password}
        />
        <Label htmlFor="newPassword" className="mb-2">
          New Password
        </Label>
        <Input
          type="password"
          id="newPassword"
          className="w-[20rem] max-w-full mb-4"
          {...newPasswordProps}
          ref={null}
          innerRef={newPasswordProps.ref}
          error={errors.newPassword}
        />
        <Label htmlFor="confirmPassword" className="mb-2">
          Confirm Password
        </Label>
        <Input
          type="password"
          id="confirmPassword"
          className="w-[20rem] max-w-full mb-4"
          {...confirmNewPasswordProps}
          ref={null}
          innerRef={confirmNewPasswordProps.ref}
          error={errors.confirmPassword}
        />

        <Button
          primary
          type="submit"
          loading={updatePasswordApi.isLoading}
          disabled={!form.formState.isDirty}
        >
          Save
        </Button>

        <RenderedErrors
          errors={errors}
          apiError={updatePasswordApi.error}
          className="bg-gbfCream-50"
        />
      </FlexCentered>
    </form>
  );
}
export default UpdatePasswordForm;
