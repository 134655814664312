function getTeamsInDateRange(days, earliestDay, latestDay) {
  let teams = {};
  days.forEach((day) => {
    if (
      day.earliestBlock >= earliestDay.earliestBlock &&
      day.lastBlock <= latestDay.lastBlock
    ) {
      teams = { ...teams, ...day.teams };
    }
  });
  return teams;
}

export default getTeamsInDateRange;
