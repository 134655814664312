import { Text, View } from "@react-pdf/renderer";

function ReportSummary({ jobSheet }) {
  return (
    <View
      style={{
        marginTop: "15px",
        border: "1px solid #c5c6ce",
        borderRadius: "5px",
        width: "50vw",
        padding: "10px",
        backgroundColor: "#ececef",
        fontWeight: "thin",
      }}
    >
      <Text
        style={{
          fontSize: "18px",
        }}
      >
        {jobSheet.team}
      </Text>
      <Text
        style={{
          fontSize: "14px",
          marginTop: "10px",
        }}
      >
        {jobSheet.job}
      </Text>
      <Text
        style={{
          fontSize: "12px",
          fontStyle: "italic",
          fontWeight: "normal",
          marginTop: "10px",
          color: "#50536b",
        }}
      >
        {jobSheet.day}
      </Text>
    </View>
  );
}
export default ReportSummary;
