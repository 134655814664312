import robotoBold from "./Roboto-Bold.ttf";
import robotoBoldItalic from "./Roboto-BoldItalic.ttf";
import robotoMedium from "./Roboto-Medium.ttf";
import robotoMediumItalic from "./Roboto-MediumItalic.ttf";
import robotoThin from "./Roboto-Thin.ttf";
import robotoThinItalic from "./Roboto-ThinItalic.ttf";

const robotoRegistration = {
  family: "Roboto",
  fonts: [
    {
      src: robotoThin,
      fontWeight: "thin",
    },
    {
      src: robotoMedium,
      fontWeight: "normal",
    },
    {
      src: robotoBold,
      fontWeight: "bold",
    },
    {
      src: robotoThinItalic,
      fontWeight: "thin",
      fontStyle: "italic",
    },
    {
      src: robotoMediumItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: robotoBoldItalic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
};

export default robotoRegistration;
