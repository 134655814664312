import { useState, createContext, useContext, useMemo } from "react";

const ViewAndEditShiftsContext = createContext();

const Provider = function ({ user, availableShifts, api, children }) {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditShiftsViewOpen, setIsEditShiftsViewOpen] = useState(false);

  //TEAMS LIST
  const teams = useMemo(() => {
    if (!availableShifts?.teams) return;
    return Object.keys(availableShifts.teams).map((teamKey) => {
      return {
        id: teamKey,
        label: availableShifts.teams[teamKey].name,
      };
    });
  }, [availableShifts]);

  //Jobs list
  const jobs = useMemo(() => {
    if (!selectedTeam || !availableShifts) return undefined;
    return Object.keys(availableShifts.teams[selectedTeam.id].jobs).map(
      (jobKey) => {
        return {
          id: jobKey,
          label: availableShifts.teams[selectedTeam.id].jobs[jobKey].name,
        };
      }
    );
  }, [availableShifts, selectedTeam]);

  //Days list
  const days = useMemo(() => {
    if (!selectedJob) return;
    return availableShifts.teams[selectedTeam.id].jobs[selectedJob.id].days.map(
      (currDay) => {
        return {
          id: currDay.day,
          label: currDay.day,
          slots: currDay.slots,
        };
      }
    );
  }, [availableShifts, selectedJob, selectedTeam]);

  //SET TEAM
  const handleSetTeam = (value, availableShifts) => {
    setSelectedTeam(value);
    const jobs = availableShifts.teams[value?.id]?.jobs || {};
    const jobKeys = Object.keys(jobs);
    if (jobKeys.length === 1) {
      setSelectedJob({
        id: jobKeys[0],
        label: jobs[jobKeys[0]].name,
      });
    } else {
      setSelectedJob(null);
    }
    setSelectedDay(null);
  };

  //SET JOB
  const handleSetJob = (value) => {
    setSelectedJob(value);
    setSelectedDay(null);
  };

  //SET DAY
  const handleSetDay = (value) => {
    setSelectedDay(value);
  };

  const handleSetEditShiftsIsOpen = (value) => {
    setIsEditShiftsViewOpen(value);
    if (value) return;

    setSelectedTeam(null);
    setSelectedJob(null);
    setSelectedDay(null);
  };

  const handleSetEdit = (ids) => {
    setIsEditShiftsViewOpen(true);
    const team = availableShifts.teams[ids.team];
    setSelectedTeam({
      id: ids.team,
      label: team.name,
    });
    const job = team.jobs[ids.job];
    setSelectedJob({
      id: ids.job,
      label: job.name,
    });
    const day = job.days[job.daysIndex[ids.day]];
    setSelectedDay({
      id: ids.day,
      label: ids.day,
      slots: day.slots,
    });
  };

  const model = {
    user,
    teams,
    jobs,
    days,
    selectedTeam,
    setSelectedTeam: handleSetTeam,
    selectedJob,
    setSelectedJob: handleSetJob,
    selectedDay,
    setSelectedDay: handleSetDay,
    isEditShiftsViewOpen,
    setIsEditShiftsViewOpen: handleSetEditShiftsIsOpen,
    isLoading: isLoading || api?.isFetching,
    setIsLoading,
    availableShifts,
    handleSetEdit,
  };

  return (
    <ViewAndEditShiftsContext.Provider value={model}>
      {children}
    </ViewAndEditShiftsContext.Provider>
  );
};

const useViewAndEditShiftsContext = function () {
  return useContext(ViewAndEditShiftsContext);
};

export { useViewAndEditShiftsContext };
export default Provider;
