import { BsFiletypeXlsx } from "react-icons/bs";
import { utils, writeFile } from "xlsx";
import { useCallback } from "react";

function ContactListXLSX({ report }) {
  const exportFile = useCallback(() => {
    const reportDate = new Date(report.query.day);

    let sortableDate = "";
    if (Number.isFinite(reportDate.getTime())) {
      sortableDate = `_${reportDate.getFullYear()}.${`${
        reportDate.getMonth() + 1
      }`.padStart(2, "0")}.${`${reportDate.getDate()}`.padStart(2, "0")}`;
    }
    const fileName = `contact-List${sortableDate}_team=${report.query.team}_job=${report.query.job}.pdf`;

    /* create workbook and append worksheet */
    const wb = utils.book_new();

    const data = report.contacts.map((contact) => {
      return {
        "Family Name": contact.familyName,
        "Given Name": contact.givenName,
        Email: contact.email,
      };
    });
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, "Contact List");

    // utils.book_append_sheet(wb, ws, "Job Sheet 1");
    /* export to XLSX */
    writeFile(wb, `${fileName}.xlsx`);
  }, [report.contacts, report.query.day, report.query.job, report.query.team]);

  return (
    <div className="w-fit bg-gbfCream-50 p-2 rounded-full cursor-pointer text-3xl text-[#217346] hover:scale-105">
      <BsFiletypeXlsx onClick={() => exportFile()} />
    </div>
  );
}
export default ContactListXLSX;
