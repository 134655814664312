const features = [
  "Backed",
  "Banded",
  "Barred",
  "Bearded",
  "Bellied",
  "Bibbed",
  "Billed",
  "Brested",
  "Browed",
  "Buffy",
  "Capped",
  "Chanting",
  "Chattering",
  "Chirping",
  "Checkered",
  "Cheeked",
  "Chested",
  "Chevroned",
  "Collared",
  "Cowled",
  "Crested",
  "Croaking",
  "Crowned",
  "Dappled",
  "Diving",
  "Dwarf",
  "Eared",
  "Edged",
  "Elf",
  "Eyed",
  "Feathered",
  "Flanked",
  "Flutist",
  "Fluttering",
  "Fronted",
  "Footed",
  "Gartered",
  "Giant",
  "Gilded",
  "Goliath",
  "Happy",
  "Headed",
  "Hooded",
  "Horned",
  "Keeled",
  "Laced",
  "Laughing",
  "Maned",
  "Mantled",
  "Masked",
  "Miniature",
  "Melodious",
  "Moustached",
  "Naped",
  "Necked",
  "Necklaced",
  "Noisy",
  "Painted",
  "Plumed",
  "Pygmy",
  "Ringed",
  "Rumped",
  "Sacred",
  "Spangled",
  "Spectacled",
  "Song",
  "Spotted",
  "Streaked",
  "Striped",
  "Tailed",
  "Tipped",
  "Tufted",
  "Throated",
  "Vampire",
  "Warbling",
  "Whiskered",
  "Winged",
  "Whistling",
];

export default features;
