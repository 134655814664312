import Heading from "../Components/Heading";
import ModalWindow from "../Components/ModalWindow";
import FlexCentered from "../Containers/FlexCentered";
import Input from "../Components/Input";
import Button from "../Components/Button";
import RenderedErrors from "../Components/RenderedErrors";
import { AiOutlineClose } from "react-icons/ai";

function GetUserCredentials({
  onClose,
  onSubmit,
  error,
  password,
  setPassword,
  loading,
}) {
  return (
    <ModalWindow options={{ blur: true }}>
      <FlexCentered col className="h-[60vh]">
        <div className="bg-gbfBlue-500 px-8 py-4 text-gbfCream-50 relative">
          <FlexCentered col>
            <Heading secondary className="text-gbfCream-50 mb-4">
              Password Required
            </Heading>
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                onSubmit();
              }}
            >
              <FlexCentered col>
                <Input
                  type="password"
                  value={password || ""}
                  onChange={(e) => setPassword(e?.target?.value)}
                  placeholder="Your password"
                  className="mb-4 text-gbfBlue-900 text-center"
                />
                <Button primary className="mb-2" loading={loading}>
                  Go
                </Button>
              </FlexCentered>
            </form>
            <RenderedErrors
              className="bg-gbfCream-50 text-gbfBlue-900"
              apiError={error}
              errors={[]}
            />
          </FlexCentered>
          <div
            className="absolute top-[-5px] right-[-5px] bg-gbfCream-50 rounded-full p-1 cursor-pointer text-red-900 hover:text-gbfCream-50 hover:bg-red-900"
            onClick={onClose}
          >
            <AiOutlineClose className="" />
          </div>
        </div>
      </FlexCentered>
    </ModalWindow>
  );
}

export default GetUserCredentials;
