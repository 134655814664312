import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import classNames from "classnames";

import { useAdminLogIn } from "../api";

import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Link from "../Components/Link";

function AdminLogInForm() {
  const logInApi = useAdminLogIn();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const emailProps = register("email", {
    required: "Email required",
  });
  const passwordProps = register("password", {
    required: "Password required",
  });

  const handleSubmit = (data) => {
    logInApi.mutate({ body: data });
  };

  const renderedErrors = Object.keys(errors).map((key) => {
    return (
      <div
        key={key}
        className="flex flex-row items-center gap-x-2 bg-gbfCream-50 max-w-full"
      >
        <AiOutlineExclamationCircle className="text-red-900 " />
        <p>{errors[key].message}</p>
      </div>
    );
  });
  if (logInApi.error) {
    renderedErrors.push(
      <div key={"serverError"} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <p>{logInApi.error}</p>
      </div>
    );
  }

  return (
    <>
      <form
        className="bg-gbfBlue-500"
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
      >
        <div className="flex flex-row items-stretch justify-stretch overflow-hidden rounded-r-lg bg-inherit">
          <FlexCentered col>
            <Input
              type="email"
              id="email"
              placeholder="email"
              className={classNames(
                "rounded-tl-lg border-gray-900 active:border-gray-900 active:bg-gbfGreen-50 focus:border-gray-900 focus:bg-gbfGreen-50 md:w-[22rem] max-w-90%",
                {
                  "bg-gbfRed-50": errors.email,
                }
              )}
              {...emailProps}
              ref={null}
              innerRef={emailProps.ref}
            />
            <Input
              type="password"
              id="password"
              placeholder="password"
              className={classNames(
                "rounded-bl-lg border-gray-900 border-t-0 active:border-gray-900 active:bg-gbfGreen-50 focus:border-gray-900 focus:bg-gbfGreen-50 md:w-[22rem] max-w-90%",
                {
                  "bg-gbfRed-50": errors.email,
                }
              )}
              {...passwordProps}
              ref={null}
              innerRef={passwordProps.ref}
            />
          </FlexCentered>
          <Button
            primary
            type="submit"
            className="h-[full] rounded-r-lg"
            loading={logInApi.isLoading}
          >
            Go
          </Button>
        </div>
        <div className="mt-2 text-gbfCream-50  ">
          <Link
            to="register"
            className="text-gbfCream-50 hover:text-gbfCream-500"
          >
            Sign-Up
          </Link>
        </div>
        <div className="mt-1 text-gbfCream-50  ">
          <Link
            to="forgot-password"
            className="text-gbfCream-50 hover:text-gbfCream-500"
          >
            Forgotten Password
          </Link>
        </div>
        {renderedErrors?.length > 0 && (
          <div className="bg-gbfCream-50 px-2 py-2  rounded-lg mt-6">
            {renderedErrors}
          </div>
        )}
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default AdminLogInForm;
