import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetAvailableShifts({ userRef, enabled = true }) {
  const queryApi = useQuery({
    queryKey: [`available-shifts-${userRef}`],
    enabled: !!userRef && enabled,
    queryFn: async () =>
      request({
        req: {
          url: `/allocations/available-shifts/${userRef}`,
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetUserRota({ userRef, enabled = true }) {
  const queryApi = useQuery({
    queryKey: [`rota-${userRef}`],
    enabled: !!userRef && enabled,
    queryFn: async () =>
      request({
        req: {
          url: `/allocations/${userRef}`,
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useEditShifts() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ userRef, body }) =>
      request({
        req: {
          url: `/allocations/${userRef}`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: (data) => {
      const userRef = data?.data?.user?._id?.toString();
      queryClient.invalidateQueries([`available-shifts-${userRef}`]);
      queryClient.invalidateQueries(["allocations"]);
      queryClient.invalidateQueries([`rota-${userRef}`]);
    },
  });
  return queryApi;
}

export { useGetAvailableShifts, useEditShifts, useGetUserRota };
