import { useCallback, useEffect, useMemo } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  usePDF,
} from "@react-pdf/renderer";
import { BsFiletypePdf } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import robotoRegistration from "../../../../assets/fonts/roboto";
import styleSheet from "./getStyleSheet";
import Header from "./Header";
import ReportSummary from "./ReportSummary";
import ReportData from "./ReportData";

function JobSheetsPDF({ report }) {
  const [instance, update] = usePDF();

  Font.register(robotoRegistration);
  const styles = StyleSheet.create(styleSheet);

  const MyDocument = useMemo(() => {
    const renderedPages = report.jobSheets.map((jobSheet, i) => {
      return (
        <Page key={i} size="A4" style={styles.page}>
          <Header styles={styles} title={report.title} />
          <View style={styles.body}>
            <ReportSummary jobSheet={jobSheet} />
            <ReportData slots={jobSheet.slots} />
          </View>
        </Page>
      );
    });
    return (
      <Document title="Job Sheets" creator="GBF" producer="GBF">
        {renderedPages}
      </Document>
    );
  }, [report, styles]);

  const triggerDownload = useCallback(() => {
    if (!instance.url) return;
    //Create file name
    const reportDate = new Date(report.query.day);
    let sortableDate = "";
    if (Number.isFinite(reportDate.getTime())) {
      sortableDate = `_${reportDate.getFullYear()}.${`${
        reportDate.getMonth() + 1
      }`.padStart(2, "0")}.${`${reportDate.getDate()}`.padStart(2, "0")}`;
    }
    const fileName = `job-sheets${sortableDate}_team=${report.query.team}_job=${report.query.job}.pdf`;

    const link = document.createElement("a");
    link.href = instance.url;
    link.setAttribute("download", `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }, [instance.url, report]);

  useEffect(() => {
    if (instance.blob && instance.url) {
      triggerDownload();
    }
  }, [instance, triggerDownload]);

  return (
    <div
      className="w-fit bg-gbfCream-50 p-2 rounded-full cursor-pointer text-3xl text-[#B30B00] hover:scale-105"
      onClick={() => {
        if (!instance.blob) {
          update(MyDocument);
        } else {
          triggerDownload();
        }
      }}
    >
      {!instance.loading && <BsFiletypePdf />}
      {instance.loading && <CgSpinner className="animate-spin opacity-70" />}
    </div>
  );
}
export default JobSheetsPDF;
