import { useLocation } from "react-router-dom";
import { useLogOut, useGetCurrentUser } from "../../api";
import getAdminLinks from "./AdminLinks";
import RenderedLinks from "./RenderedLinks";
import getPublicLinks from "./getPublicLinks";

function NavLinks() {
  const location = useLocation();
  const logOutAPI = useLogOut();

  const handleLogOut = () => {
    logOutAPI.mutate();
  };

  const currentUserApi = useGetCurrentUser();
  const currentUser = currentUserApi.data;
  if (!currentUser) return;

  const adminRoute = location.pathname.startsWith("/admin");
  const userRoute = !adminRoute && currentUser?.isLoggedIn;

  const adminLinks = adminRoute ? getAdminLinks(currentUser, handleLogOut) : [];
  const publicLinks = userRoute
    ? getPublicLinks(currentUser, handleLogOut)
    : [];
  const dashboardLinks = [];
  const links = [...adminLinks, ...publicLinks, ...dashboardLinks];

  return (
    <div className="bg-gbfYellow-50 px-4 py-2 rounded-lg flex flex-row items-center justify-center gap-4">
      <RenderedLinks links={links} />
    </div>
  );
}

export default NavLinks;
