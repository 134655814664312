import { useState, useRef } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import ModalWindow from "../../../ModalWindow";
import classNames from "classnames";
import SetFilter from "./SetFilter";

function FilterIcon() {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef(null);

  return (
    <>
      <div ref={iconRef}>
        <BiFilterAlt
          className={classNames("cursor-pointer hover:text-gbfGreen-800", {
            "text-gbfGreen-800": isOpen,
          })}
          onClick={(e) => {
            e?.stopPropagation();
            setIsOpen((cur) => !cur);
          }}
        />
      </div>
      {isOpen && (
        <ModalWindow
          options={{
            relativeTo: {
              ref: iconRef?.current,
            },
            pos: {
              bottom: 0,
              right: 0,
            },
            modalIncludes: [
              "fields-dropdown",
              "operators-dropdown",
              "values-dropdown",
            ],
            onClickOut: () => setIsOpen(false),
            onResize: () => setIsOpen(false),
          }}
        >
          <div className="relative bg-gbfBlue-600 rounded-lg p-4 shadow-lg relative">
            <SetFilter onClose={() => setIsOpen(false)} />
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className="absolute top-[5px] right-[5px] text-lg  bg-red-800 hover:bg-red-700 cursor-pointer text-gbfCream-50 p-1 text-xl rounded-full"
          >
            <AiOutlineClose />
          </div>
        </ModalWindow>
      )}
    </>
  );
}
export default FilterIcon;
