import { useGetSlotsByJob } from "../../../../api";
import FlexCentered from "../../../../Containers/FlexCentered";
import AddSlot from "../../../../forms/rota/AddSlot";
import Loading from "../../../../Components/Loading";
import P from "../../../../Components/P";
import RenderedSlots from "./RenderedSlots";
import { useEditRotaContext } from "../../../../providers/EditRotaContext";

function Slots() {
  const { rota, activeTeam, activeJob } = useEditRotaContext();

  const getSlotsApi = useGetSlotsByJob({
    rotaRef: rota?._id,
    teamRef: activeTeam?._id,
    jobRef: activeJob?._id,
  });

  const slots = getSlotsApi?.data?.slots;

  let renderedSlots;

  if (getSlotsApi.isLoading) {
    renderedSlots = <Loading />;
  } else if (getSlotsApi.error) {
    renderedSlots = <P className="text-red-900">{getSlotsApi.error}</P>;
  } else if (slots && slots.length > 0) {
    renderedSlots = <RenderedSlots slots={slots} />;
  } else {
    renderedSlots = "No slots found";
  }

  return (
    <div>
      <FlexCentered col className="w-full">
        {renderedSlots}
        {slots && <AddSlot forceOpen={slots.length === 0} />}
      </FlexCentered>
    </div>
  );
}

export default Slots;
