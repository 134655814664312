import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";
import FlexCentered from "../Containers/FlexCentered";

function Button({
  primary,
  secondary,
  danger,
  outline,
  rounded,
  loading,
  disabled,
  className,
  children,
  ...props
}) {
  const classes = classNames(
    "border font-semibold tracking-wide outline-none focus:ring-gbfGreen-200 active:ring-gbfGreen-200",
    {
      "border-gbfGreen-800 hover:border-gbfGreen-600 ": primary,
      "border-gbfBlue-700 hover:border-gbfBlue-500": secondary,
      "border-gray-900 hover:bg-gbfBlue-50": !primary && !secondary,
      "border-red-900 hover:border-red-800": danger,
      "bg-gbfGreen-800 hover:bg-gbfGreen-600": primary && !outline,
      "bg-gbfBlue-700 hover:bg-gbfBlue-500": secondary && !outline,
      "hover:bg-gbfGreen-50": primary && outline,
      "hover:bg-gbfBlue-50": secondary && outline,
      "bg-red-900 hover:bg-red-800": danger && !outline,
      "text-gbfCream-50": !outline && (primary || secondary || danger),
      rounded: rounded,
      "pointer-events-none opacity-50": disabled,
      "px-4": !(className || "").includes("px"),
      "py-2": !(className || "").includes("py"),
    },
    className
  );
  return (
    <button {...props} className={classes}>
      {!loading && children}
      {loading && (
        <FlexCentered col>
          <CgSpinner className="animate-spin" />
        </FlexCentered>
      )}
    </button>
  );
}

export default Button;
