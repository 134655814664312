import { Routes, Route } from "react-router-dom";
import AdminRouter from "./routes/AdminRouter";
import PublicRouter from "./routes/PublicRouter";

function App() {
  return (
    <Routes>
      <Route path="/admin/*" element={<AdminRouter />} />
      <Route path="/*" element={<PublicRouter />} />
    </Routes>
  );
}

export default App;
