import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";
import P from "../../../Components/P";
import ContactListXLSX from "./ContactListXLSX";

function ContactsListReportView({ report }) {
  if (!report) return;

  let renderedUsers = <P>No matching contacts</P>;
  if (report.contacts.length > 0) {
    const headerClasses =
      "px-2 border border-gbfBlue-300 text-center text-gbfCream-50 bg-gbfBlue-900";
    const header = (
      <thead>
        <tr>
          <td className={headerClasses}>Family Name</td>
          <td className={headerClasses}>Given Name</td>
          <td className={headerClasses}>Email</td>
        </tr>
      </thead>
    );

    const dataClasses = "px-2 border border-gbfBlue-300";
    const rows = report.contacts.map((vol) => {
      return (
        <tr key={vol._id} className="py-1">
          <td className={dataClasses}>{vol.familyName}</td>
          <td className={dataClasses}>{vol.givenName}</td>
          <td className={dataClasses}>{vol.email}</td>
        </tr>
      );
    });

    renderedUsers = (
      <table className="table-auto border-spacing-0 ">
        {header}
        <tbody>{rows}</tbody>
      </table>
    );
  }

  return (
    <div>
      <FlexCentered col>
        <div className="bg-gbfBlue-100 w-full max-w-3xl mb-56">
          <div className="grid grid-cols-[1fr_auto] px-2 py-4 bg-gbfBlue-900 text-gbfCream-50 ">
            <div className="grid grid-cols-[1fr_auto] gap-x-4 w-fit">
              <Heading secondary className="col-span-2 text-gbfBlue-100">
                Contact List Report
              </Heading>
              <div>Team:</div>
              <div>{report.query.team}</div>
              <div>Job:</div>
              <div>{report.query.job}</div>
              <div>Day:</div>
              <div>
                {new Date(report.query.day).toLocaleDateString("en-gb")}
              </div>
            </div>
            <FlexCentered row className="w-fit gap-x-2 h-fit">
              {report.contacts.length > 0 && (
                <ContactListXLSX report={report} />
              )}
            </FlexCentered>
          </div>
          <div className="px-2 py-8">{renderedUsers}</div>
        </div>
      </FlexCentered>
    </div>
  );
}

export default ContactsListReportView;
