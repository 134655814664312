import { AiOutlineExclamationCircle } from "react-icons/ai";
import P from "./P";
import classNames from "classnames";

function RenderedErrors({ errors, apiError, className }) {
  const renderedErrors = Object.keys(errors).map((key) => {
    return (
      <div key={key} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 text-xl shrink-0" />
        <P>{errors[key].message}</P>
      </div>
    );
  });
  if (apiError) {
    renderedErrors.push(
      <div key={"serverError"} className="flex flex-row items-start gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 text-xl shrink-0" />
        {apiError}
      </div>
    );
  }
  if (renderedErrors.length === 0) return undefined;
  return (
    <div
      className={classNames(
        "px-2 py-2 rounded-lg mt-6 border border-gbfBlue-500 ",
        className
      )}
    >
      <div className="flex flex-col gap-y-2">{renderedErrors}</div>
    </div>
  );
}

export default RenderedErrors;
