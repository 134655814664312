import { useState, useEffect } from "react";
import { useGetJobsByTeam, useEditJob, useDeleteJob } from "../../../../api";
import { useToastContext } from "../../../../providers/toastContext";
import AddJob from "../../../../forms/rota/AddJob";
import FlexCentered from "../../../../Containers/FlexCentered";
import Loading from "../../../../Components/Loading";
import P from "../../../../Components/P";
import classNames from "classnames";
import { useEditRotaContext } from "../../../../providers/EditRotaContext";
import {
  BsChevronLeft,
  BsChevronDown,
  BsPencil,
  BsTrash,
  BsXLg,
} from "react-icons/bs";
import Slots from "./Slots";

function Jobs() {
  const [editing, setIsEditing] = useState(null);
  const [jobName, setJobName] = useState("null");

  const rotaContext = useEditRotaContext();

  const editJobApi = useEditJob();
  const deleteJobApi = useDeleteJob();

  const { createErrorToast, createSuccessToast } = useToastContext();
  useEffect(() => {
    if (editJobApi.error) {
      setIsEditing(null);
      setJobName("");
      createErrorToast(editJobApi.error);
      editJobApi.reset();
    }
  }, [createErrorToast, editJobApi]);

  useEffect(() => {
    if (editJobApi.isSuccess) {
      setIsEditing(null);
      setJobName("");
      editJobApi.reset();
    }
  }, [editJobApi]);

  const handleSubmitEdit = () => {
    editJobApi.mutate({
      rotaRef: rotaContext.rota._id,
      teamRef: rotaContext.activeTeam?._id,
      jobRef: editing?._id,
      body: {
        name: jobName,
      },
    });
  };

  const handleDelete = (id) => {
    rotaContext.setActiveJob(null);
    rotaContext.setIsLoading(true);
    deleteJobApi.mutate({
      rotaRef: rotaContext?.rota,
      teamRef: rotaContext?.activeTeam,
      jobRef: id,
    });
  };

  useEffect(() => {
    if (deleteJobApi?.isSuccess) {
      deleteJobApi.reset();
      createSuccessToast("Job deleted");
      rotaContext.setIsLoading(false);
    }
  }, [createSuccessToast, deleteJobApi, rotaContext]);

  useEffect(() => {
    if (deleteJobApi?.error) {
      createErrorToast(deleteJobApi?.error);
      deleteJobApi.reset();
      rotaContext.setIsLoading(false);
    }
  }, [createErrorToast, deleteJobApi, rotaContext]);

  const getJobsApi = useGetJobsByTeam({
    rotaRef: rotaContext.rota._id,
    teamRef: rotaContext.activeTeam?._id,
  });
  const jobs = getJobsApi?.data?.jobs;

  if (getJobsApi.isLoading) {
    return (
      <FlexCentered row className="my-2">
        <Loading />
      </FlexCentered>
    );
  }
  if (getJobsApi.error || !jobs) {
    return (
      <div className="my-2 text-red-900">
        <P>Failed to fetch jobs data. Please try again later</P>
      </div>
    );
  }

  const renderedJobs = jobs.map((job) => {
    const isEditing = editing === job;
    const isOpen =
      rotaContext?.activeJob?._id?.toString() === job?._id?.toString();

    return (
      <div key={job._id} className="w-full">
        <div
          className={classNames("border w-full px-4 py-2 shadow select-none", {
            "bg-gbfYellow-100": !isOpen,
            "bg-gbfYellow-500 ": isOpen && !isEditing,
            "bg-gbfBlue-200 bg-opacity-50": isEditing,
          })}
        >
          <div className="grid grid-cols-[1fr_auto_auto_auto] gap-x-4 items-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitEdit();
              }}
            >
              <input
                value={
                  !isEditing
                    ? job.name
                    : editJobApi.isLoading
                    ? "Loading..."
                    : jobName
                }
                onChange={(e) => setJobName(e?.target?.value)}
                disabled={!isEditing || editJobApi.isLoading}
                className={classNames(
                  "w-full px-2 py-1 rounded ring-transparent border border-transparent outline-none focus:border-gbfGreen-900",
                  {
                    "bg-transparent": !isEditing,
                  }
                )}
              />
            </form>
            {!isEditing && (
              <BsPencil
                className={classNames(
                  "w-fit cursor-pointer hover:text-gbfGreen-900"
                )}
                onClick={() => {
                  setIsEditing(job);
                  setJobName(job.name);
                  rotaContext?.setActiveJob(null);
                }}
              />
            )}
            {isEditing && (
              <BsXLg
                className="w-fit  text-red-700 hover:text-red-900 cursor-pointer"
                onClick={() => {
                  setIsEditing(null);
                  setJobName(null);
                }}
              />
            )}
            <BsTrash
              className="w-fit cursor-pointer hover:text-red-700"
              onClick={() => handleDelete(job._id)}
            />
            {!isOpen && (
              <BsChevronLeft
                className=" w-fit cursor-pointer hover:text-gbfGreen-900"
                onClick={() => {
                  rotaContext?.setActiveJob((curr) =>
                    curr?._id?.toString() === job?._id?.toString() ? null : job
                  );
                  setIsEditing(null);
                  setJobName("");
                }}
              />
            )}
            {isOpen && (
              <BsChevronDown
                className="cursor-pointer hover:text-gbfGreen-900"
                onClick={() => {
                  rotaContext?.setActiveJob((curr) =>
                    curr?._id?.toString() === job?._id?.toString() ? null : job
                  );
                  setIsEditing(null);
                  setJobName("");
                }}
              />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="w-full p-2 pb-8 bg-gray-200 bg-opacity-50">
            <Slots />
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      <div>
        <div className="mt-4 ">
          <FlexCentered col className="pb-8 gap-y-1">
            {renderedJobs}
            <AddJob forceOpen={!jobs?.length} />
          </FlexCentered>
        </div>
      </div>
    </div>
  );
}
export default Jobs;
