import Page from "../../Containers/Page";
import FlexCentered from "../../Containers/FlexCentered";
import AdminLogInForm from "../../forms/AdminLogInForm";
import Heading from "../../Components/Heading";

function AdminLogIn() {
  return (
    <Page title="Admin - Dashboard" className="bg-gbfBlue-500">
      <FlexCentered col className="pt-[15vh]">
        <Heading primary className="text-gbfCream-100 mb-8">
          GBF Admin
        </Heading>
        <AdminLogInForm />
      </FlexCentered>
    </Page>
  );
}
export default AdminLogIn;
