import { useEffect, useState } from "react";
import {
  useGetUser,
  useGetUserCredentials,
  useMarkCredentialsAsCommunicated,
} from "../../../api";
import { useParams } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Page from "../../../Containers/Page";
import getApiStatusPage from "../../APIStatusPage";
import Heading from "../../../Components/Heading";
import FlexCentered from "../../../Containers/FlexCentered";
import P from "../../../Components/P";
import classNames from "classnames";
import GetUserCredentials from "../../../forms/GetUserCredentials.js";

import ViewAndEditShifts from "../../../sections/ViewAndEditShifts";
import Button from "../../../Components/Button";
import Link from "../../../Components/Link";

function UserPage() {
  const [showPasscode, setShowPasscode] = useState(false);
  const [password, setPassword] = useState(false);
  const [fetchPasscode, setFetchPasscode] = useState(false);

  const { userRef } = useParams();
  const getUserApi = useGetUser({
    userRef,
    params: {
      fields:
        "fullName,email,userName,password,givenName,familyName,credentialsCommunicated",
    },
  });
  const user = getUserApi?.data?.user;

  const getUserCredentialsApi = useGetUserCredentials({
    userRef: user?._id,
    enabled: fetchPasscode,
    params: { password },
  });

  const updateCredentialsApi = useMarkCredentialsAsCommunicated();

  const credentials = getUserCredentialsApi?.data?.credentials;
  useEffect(() => {
    if (credentials && !showPasscode) {
      getUserCredentialsApi.remove();
      setFetchPasscode(false);
      setPassword("");
    }
  }, [credentials, getUserCredentialsApi, showPasscode]);

  useEffect(() => {
    if (getUserCredentialsApi.isFetching) {
      setPassword("");
      setFetchPasscode(false);
    }
  }, [getUserCredentialsApi]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;
  if (!user) return;

  const handleUpdateCredentials = () => {
    updateCredentialsApi.mutate({ userRef: user._id });
  };

  return (
    <Page title={user?.fullName || "user"}>
      {showPasscode && !credentials && (
        <GetUserCredentials
          password={password}
          setPassword={setPassword}
          onClose={() => {
            setShowPasscode(false);
            setPassword("");
          }}
          onSubmit={() => setFetchPasscode(true)}
          error={getUserCredentialsApi.error}
          loading={getUserCredentialsApi.isFetching}
        />
      )}
      <FlexCentered col className="pt-8">
        <FlexCentered row className="gap-x-4">
          <Heading primary>{user.fullName}</Heading>
          <Link to={`/admin/users/${userRef}/edit`}>
            <Button secondary outline className="px-2 py-1">
              Edit
            </Button>
          </Link>
        </FlexCentered>

        <Heading secondary>{user.email}</Heading>

        <div className="w-full max-w-lg mt-8">
          <Heading tertiary>Credentials</Heading>
          <div className="grid grid-cols-[auto_auto] gap-x-2 gap-y-1 w-fit mt-2">
            <P>Username:</P>
            <P strong>{user.userName}</P>
            <P>Passcode:</P>
            <div className=" w-fit grid grid-cols-[auto_auto] gap-x-2 items-center">
              <div className="overflow-hidden">
                <P
                  strong
                  className={classNames({
                    "blur-md": !showPasscode || !credentials,
                  })}
                >
                  {credentials?.passcode || "??????"}
                </P>
              </div>
              {(!showPasscode || !credentials) && (
                <AiOutlineEye
                  className="cursor-pointer"
                  onClick={() => setShowPasscode(true)}
                />
              )}
              {showPasscode && credentials && (
                <AiOutlineEyeInvisible
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPasscode(false);
                    getUserCredentialsApi.remove();
                  }}
                />
              )}
            </div>
            <P>Communicated:</P>
            <P
              strong
              className={classNames({
                "text-red-900": user.credentialsCommunicated === "Failed",
                "text-green-900":
                  user.credentialsCommunicated === "Communicated",
              })}
            >
              {user.credentialsCommunicated === "Communicated"
                ? "Yes"
                : user.credentialsCommunicated === "Failed"
                ? "No"
                : "Pending"}
            </P>
          </div>
        </div>
        {user.credentialsCommunicated !== "Communicated" && (
          <div className="w-full max-w-lg mt-4 ">
            <P
              className={classNames({
                "opacity-50 pointer-events-none":
                  updateCredentialsApi.isLoading,
              })}
            >
              Click{" "}
              <span
                className="font-semibold cursor-pointer"
                onClick={handleUpdateCredentials}
              >
                here
              </span>{" "}
              to mark credentials as sent manually
            </P>
          </div>
        )}
        <div className="mt-8 w-full max-w-lg">
          <ViewAndEditShifts user={user} isAdmin />
        </div>
      </FlexCentered>
    </Page>
  );
}
export default UserPage;
