import Searchbar from "./Searchbar";
import Settings from "./Settings";
import FilterIcon from "./FilterIcon";
import FiltersList from "./FiltersList";
import CSVIcon from "./CSVIcon";

function Controls() {
  const filter = <FilterIcon />;
  const searchbar = <Searchbar />;
  const filtersList = <FiltersList />;
  const CSV = <CSVIcon />;

  if (!filter && !searchbar) return;

  return (
    <div className="bg-grey-300 mx-2 rounded-t-lg pb-2 px-2">
      {filter && (
        <div className="flex flex-row items-center justify-end text-xl py-2 gap-x-1">
          {CSV}
          {filter}
        </div>
      )}
      {searchbar}
      {filtersList}
    </div>
  );
}

export default Controls;
