import { useEffect, useState } from "react";
import {
  useGetTeamsJobsAndDays,
  useGetJobSheets,
  useGetContactList,
} from "../../../api";
import getApiStatusPage from "../../APIStatusPage";
import Heading from "../../../Components/Heading";
import FlexCentered from "../../../Containers/FlexCentered";
import Page from "../../../Containers/Page";
import classNames from "classnames";
import Filters from "./Filters";
import JobSheetsReportView from "./JobSheetsReportView";
import ContactsListReportView from "./ContactsListReportView";

function ReportsPage() {
  const [reportType, setReportType] = useState("jobSheets");
  const [activeTeam, setActiveTeam] = useState(null);
  const [activeJob, setActiveJob] = useState(null);
  const [activeDay, setActiveDay] = useState(null);
  const [activeQuery, setActiveQuery] = useState(null);
  const [activeReport, setActiveReport] = useState(null);

  const rotaReportApi = useGetTeamsJobsAndDays();

  const getContactListApi = useGetContactList({
    enabled: !!activeQuery && activeQuery.type === "contactLists",
    params: activeQuery,
  });
  const getJobSheetsApi = useGetJobSheets({
    enabled: !!activeQuery && activeQuery.type === "jobSheets",
    params: activeQuery,
  });

  useEffect(() => {
    if (!activeQuery) return;
    if (activeQuery.type === "jobSheets") {
      if (!getJobSheetsApi.data || getJobSheetsApi.isFetching) return;
      if (getJobSheetsApi?.data?.key !== activeQuery.key) {
        getJobSheetsApi.refetch({
          enabled: true,
          params: activeQuery,
        });
      }
    }
    if (activeQuery.type === "contactLists") {
      if (!getContactListApi?.data || getContactListApi.isFetching) return;
      if (getContactListApi?.data?.key !== activeQuery.key) {
        getContactListApi.refetch({
          enabled: true,
          params: activeQuery,
        });
      }
    }
  }, [activeQuery, getContactListApi, getJobSheetsApi]);

  useEffect(() => {
    if (!activeQuery) return;
    if (activeQuery.key === activeReport?.key) return;
    if (
      getJobSheetsApi.data &&
      getJobSheetsApi?.data?.key === activeQuery.key
    ) {
      setActiveReport({
        key: getJobSheetsApi.data.key,
        type: "jobSheets",
        jobSheets: getJobSheetsApi.data.jobSheets,
        title: getJobSheetsApi.data.title,
        query: getJobSheetsApi.data.filters,
      });
    } else if (
      getContactListApi.data &&
      getContactListApi?.data?.key === activeQuery.key
    ) {
      setActiveReport({
        key: getContactListApi.data.key,
        type: "contactLists",
        contacts: getContactListApi.data.contacts,
        title: getContactListApi.data.title,
        query: getContactListApi.data.filters,
      });
    }
  }, [
    activeQuery,
    activeReport?.key,
    getContactListApi.data,
    getJobSheetsApi.data,
  ]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: rotaReportApi,
        type: "Rota",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const data = rotaReportApi?.data;
  if (!data) return;
  const { teams, days } = rotaReportApi.data;

  const handleGenerateQuery = () => {
    let from;
    let to;
    if (activeDay) {
      from = new Date(new Date(activeDay).setHours(0, 0, 0, 1));
      to = new Date(new Date(activeDay).setHours(23, 59, 59, 999));
    }

    setActiveQuery({
      type: reportType,
      team: activeTeam?.id,
      job: activeJob?.id,
      from,
      to,
      key: `${reportType}-${activeTeam?.id || "*"}-${activeJob?.id || "*"}-${
        activeDay || "*"
      }`,
    });
  };

  const handleUpdateReportType = (type) => {
    setReportType(type);
    setActiveTeam(null);
    setActiveJob(null);
    setActiveDay(null);
    setActiveQuery(null);
  };

  return (
    <Page title="Reports">
      <div className="pt-12">
        <FlexCentered col>
          <Heading primary className="mb-8">
            Reports
          </Heading>
          <div className="bg-gbfBlue-100 mb-4 w-full max-w-xl">
            <div className="grid grid-cols-2">
              <div
                className={classNames("py-2 select-none", {
                  "bg-gbfGreen-200": reportType === "jobSheets",
                  "cursor-pointer": reportType !== "jobSheets",
                })}
                onClick={() => handleUpdateReportType("jobSheets")}
              >
                <Heading secondary className="text-center">
                  Job Sheets
                </Heading>
              </div>
              <div
                className={classNames("py-2 select-none", {
                  "bg-gbfGreen-200": reportType === "contactLists",
                  "cursor-pointer": reportType !== "contactLists",
                })}
                onClick={() => handleUpdateReportType("contactLists")}
              >
                <Heading secondary className="text-center">
                  Contact Lists
                </Heading>
              </div>
            </div>
            <div className="bg-gbfGreen-200 py-4 px-2">
              <Filters
                teams={teams}
                days={days}
                activeTeam={activeTeam}
                setActiveTeam={setActiveTeam}
                activeJob={activeJob}
                setActiveJob={setActiveJob}
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                onGenerateQuery={handleGenerateQuery}
              />
            </div>
          </div>
        </FlexCentered>
        {activeQuery && activeQuery.type === "jobSheets" && (
          <JobSheetsReportView
            report={
              activeReport && activeReport.key === activeQuery?.key
                ? activeReport
                : null
            }
          />
        )}
        {activeQuery && activeQuery.type === "contactLists" && (
          <ContactsListReportView
            report={
              activeReport && activeReport.key === activeQuery?.key
                ? activeReport
                : null
            }
          />
        )}
      </div>
    </Page>
  );
}
export default ReportsPage;
