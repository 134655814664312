import { useMemo } from "react";
import Slot from "./Slot";

function Slots({
  rota,
  rowIndex,
  rowHeight,
  blockWidth,
  visibleBlockRange,
  activeSlot,
  setActiveSlot,
}) {
  const renderedSlots = [];
  const incompatibleSlots = useMemo(() => {
    if (!activeSlot) return {};
    const incompatibilities = {};
    activeSlot.incompatibleSlots.forEach((slot) => {
      incompatibilities[slot] = 1;
    });
    return incompatibilities;
  }, [activeSlot]);

  const numInJob = {};

  rota.slots.forEach((slot) => {
    if (rowIndex[slot.job] === undefined) return;
    if (slot.endBlock < visibleBlockRange[0]) return;
    if (slot.startBlock > visibleBlockRange[1]) return;

    numInJob[slot.job] = (numInJob[slot.job] || 0) + 1;

    const row = rowIndex[slot.job];
    const top = row * rowHeight;
    const left = (slot.startBlock - 1) * blockWidth;
    const width = (slot.endBlock - slot.startBlock) * blockWidth;
    const position = {
      left: `${left}px`,
      width: `${width}px`,
      height: `${rowHeight}px`,
      top: `${top}px`,
    };

    renderedSlots.push(
      <Slot
        slot={slot}
        position={position}
        key={slot._id}
        isActive={slot === activeSlot}
        isIncompatible={!!incompatibleSlots[slot._id]}
        setActiveSlot={setActiveSlot}
      />
    );
  });

  return <>{renderedSlots}</>;
}
export default Slots;
