import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetCurrentUser() {
  const queryApi = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () =>
      request({
        req: {
          url: "/auth/current-user",
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useAdminLogIn() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: "auth/admin/log-in",
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => queryClient.invalidateQueries(["currentUser"]),
  });

  return queryApi;
}

function useUserLogIn() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: "auth/sign-in",
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => queryClient.invalidateQueries(["currentUser"]),
  });

  return queryApi;
}

function useLogOut() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async () =>
      request({
        req: {
          url: "auth/log-out",
          method: "POST",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useResetPassword() {
  const queryApi = useMutation({
    mutationFn: async ({ body, token }) =>
      request({
        req: {
          url: `auth/admin/reset-password/${token}`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
  });
  return queryApi;
}

function useForgotPassword() {
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/forgot-password`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
  });
  return queryApi;
}

function useConfirmEmail() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/confirm-email`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useResendConfirmationCode() {
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/resend-confirmation-code`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
  });
  return queryApi;
}

function useRegister() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/register`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useCreateUser() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/create-user`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
  return queryApi;
}

function useUpdatePassword() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `auth/admin/update-password`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {},
  });
  return queryApi;
}

export {
  useGetCurrentUser,
  useAdminLogIn,
  useLogOut,
  useResetPassword,
  useForgotPassword,
  useConfirmEmail,
  useResendConfirmationCode,
  useRegister,
  useCreateUser,
  useUserLogIn,
  useUpdatePassword,
};
