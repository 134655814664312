//Calculates visible blocks (assuming full width component).
//Returns array [start,end]
function calculateVisibleBlocks(
  x,
  sideBarWidth,
  blockWidth,
  earliestDate,
  latestDate
) {
  if (!earliestDate) return;

  const selectedRange = [earliestDate.earliestBlock, latestDate.lastBlock];

  //Visible BLOCKS
  const firstBlock = x / blockWidth;
  const blocksPerWindow = Math.ceil(
    (window.innerWidth - sideBarWidth) / blockWidth
  );
  const lastBlock = Math.min(firstBlock + blocksPerWindow, selectedRange[1]);

  return [Math.max(firstBlock, selectedRange[0]), lastBlock];
}
export default calculateVisibleBlocks;
