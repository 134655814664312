import { useGetCurrentUser } from "../api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserLogInForm from "../forms/UserLogInForm";
import Heading from "../Components/Heading";

function Home() {
  const currentUserAPI = useGetCurrentUser();
  const navigateTo = useNavigate();
  useEffect(() => {
    if (currentUserAPI.data?.isAdmin && currentUserAPI.data?.isLoggedIn) {
      return navigateTo("/admin");
    }
  }, [
    currentUserAPI.data?.isAdmin,
    currentUserAPI.data?.isLoggedIn,
    navigateTo,
  ]);

  const currentUser = currentUserAPI.data;
  if (!currentUser) return <div>Loading</div>;

  return (
    <div className="bg-gbfBlue-500 min-h-[100vh] flex flex-col items-center pt-[15vh]">
      <Heading primary className="text-gbfCream-50 mb-1">
        Global Birdfair Volunteers
      </Heading>
      <Heading secondary className="text-gbfYellow-100 mb-8">
        Shift Selection
      </Heading>
      <UserLogInForm />
    </div>
  );
}

export default Home;
