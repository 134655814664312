import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { useResetPassword } from "../api";

import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import P from "../Components/P";
import Link from "../Components/Link";

function ResetPasswordForm() {
  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const resetPasswordApi = useResetPassword();
  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const passwordProps = register("password", {
    required: "New password required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters",
    },
  });
  const confirmPasswordProps = register("confirmPassword", {
    required: "confirm password required",
    validate: (value, formValues) => {
      return formValues.password === value || "The passwords do not match";
    },
  });

  const handleSubmit = (data) => {
    resetPasswordApi.mutate({ body: data, token });
    form.reset();
  };

  const renderedErrors = Object.keys(errors).map((key) => {
    return (
      <div key={key} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <P>{errors[key].message}</P>
      </div>
    );
  });
  if (resetPasswordApi.error) {
    renderedErrors.push(
      <div key={"serverError"} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <div>
          {resetPasswordApi.error.includes("Invalid token") ? (
            <P>
              Invalid token; note, tokens expire after 30 mins. To get a fresh
              token, click{" "}
              <Link to="/admin" className="underline">
                here
              </Link>{" "}
              to return to the sign-in page and select "forgotten password"
            </P>
          ) : (
            resetPasswordApi.error
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className="pt-[10vh]"
      >
        <FlexCentered col>
          <Heading primary className="text-gbfBlue-500 mb-6">
            Reset Your Password
          </Heading>
          <Input
            type="password"
            id="password"
            placeholder="New Password"
            className="w-[20rem] max-w-full mb-2"
            {...passwordProps}
            ref={null}
            innerRef={passwordProps.ref}
          />
          <Input
            type="password"
            id="confirmPassword"
            placeholder="Confirm Password"
            className="w-[20rem] max-w-full mb-4"
            {...confirmPasswordProps}
            ref={null}
            innerRef={confirmPasswordProps.ref}
          />
          <Button primary type="submit" loading={resetPasswordApi.isLoading}>
            Set
          </Button>

          {renderedErrors?.length > 0 && !resetPasswordApi.data && (
            <div className="px-2 py-2 rounded-lg mt-6 border border-gbfBlue-500 ">
              {renderedErrors}
            </div>
          )}
          {resetPasswordApi.data && (
            <FlexCentered row className="gap-2 mt-4">
              <AiOutlineCheckCircle className="text-gbfGreen-900" />
              <P>
                Your password has been reset. Return to{" "}
                <Link to="/admin" className="underline">
                  sign-in
                </Link>{" "}
                page.
              </P>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default ResetPasswordForm;
