const habitats = [
  "Barn",
  "Bracken",
  "Bush",
  "Cattle",
  "Cave",
  "Coast",
  "Crag",
  "Field",
  "Forest",
  "Foliage",
  "Foothill",
  "Garden",
  "Grass",
  "Ground",
  "Hermit",
  "Hill",
  "Leaf",
  "Marsh",
  "Meadow",
  "Mistle",
  "Moor",
  "Mountain",
  "Outcrop",
  "Pond",
  "Prairie",
  "Reed",
  "River",
  "Rock",
  "Sand",
  "Scrub",
  "Sea",
  "Stone",
  "Stubble",
  "Swamp",
  "Tree",
  "Water",
  "Wood",
  "Upland",
  "Village",
  "Volcano",
];

export default habitats;
