import { useGetCurrentUser } from "../../api";
import Heading from "../../Components/Heading";
import FlexCentered from "../../Containers/FlexCentered";
import Page from "../../Containers/Page";
import ViewAndEditShifts from "../../sections/ViewAndEditShifts";
import getApiStatusPage from "../APIStatusPage";

function UserDashboardPage() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCurrentUserApi,
        type: "User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;
  if (!currentUser) return;

  return (
    <Page title="Dashboard">
      <FlexCentered col>
        <FlexCentered col className="w-full max-w-lg pt-12 pb-8">
          <Heading secondary className="mb-8">
            Hello {currentUser?.givenName}
          </Heading>
          <ViewAndEditShifts user={currentUser} />
        </FlexCentered>
      </FlexCentered>
    </Page>
  );
}
export default UserDashboardPage;
