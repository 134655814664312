import { useMemo, useRef } from "react";
import Input from "../../../Input";
import Button from "../../../Button";
import { useTableContext } from "../../TableContext";

function Searchbar() {
  const searchbarRef = useRef(null);
  const { visibleColumns, setQuery } = useTableContext();

  //Get searchable fields and placeholder from visible columns
  const [searchableFields, placeholder] = useMemo(() => {
    const searchableFields = [];
    const searchableLabels = [];
    visibleColumns.forEach((col) => {
      if (!col.searchable) return;
      if (col.searchFieldNames) {
        col.searchFieldNames?.forEach((field) => {
          searchableFields.push(field);
        });
      } else {
        col.fieldNames?.forEach((field) => {
          searchableFields.push(field);
        });
      }

      searchableLabels.push(col.label);
    });
    let placeholder = "Search ";
    searchableLabels.forEach((label, i) => {
      if (i === 0) placeholder += `${label}`;
      else if (i < searchableLabels.length - 1) placeholder += `, ${label}`;
      else placeholder += ` or ${label}`;
    });
    return [searchableFields, placeholder];
  }, [visibleColumns]);

  const handleSearch = (e) => {
    e?.preventDefault();
    if (!searchbarRef.current) return;
    const searchterm = searchbarRef.current.value;
    setQuery?.search(searchterm, searchableFields);
  };

  if (!searchableFields || searchableFields.length === 0) return;

  return (
    <form
      className="flex flex-row items-stretch justify-center"
      onSubmit={handleSearch}
    >
      <Input
        className="w-full h-10 border border-gbfBlue-100 rounded-l"
        placeholder={placeholder}
        innerRef={searchbarRef}
      />
      <Button primary className="px-2 py-1">
        Search
      </Button>
    </form>
  );
}
export default Searchbar;
