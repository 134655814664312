import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import JobSheetsPDF from "./JobSheetsPdf";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";
import { useState } from "react";
import classNames from "classnames";
import JobSheetsXLSX from "./JobSheetsXLSX";

function JobSheetsReportView({ report }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  if (!report || report.type !== "jobSheets") return;

  const jobSheet = report.jobSheets[currentIndex];

  const renderedSlots = jobSheet.slots.map((slot, i) => {
    const renderedVols = slot.volunteers.map((vol, i) => {
      return <div key={i}>{vol}</div>;
    });
    return (
      <div key={i} className="mb-4">
        <div className="pb-1 border-b-2 mb-2">
          <Heading tertiary>
            {slot.start} - {slot.end}{" "}
          </Heading>
        </div>
        {renderedVols.length > 0 && renderedVols}
        {renderedVols.length === 0 && (
          <div className="text-[#864939]">No Volunteers</div>
        )}
      </div>
    );
  });

  const renderedPage = (
    <div className="min-h-[50vh]">
      {(report.query.team === "Any" ||
        report.query.job === "Any" ||
        report.query.day === "Any") && (
        <div className="grid grid-cols-[1fr_auto] gap-x-4 w-fit font-semibold tracking-wide mb-8">
          {report.query.team === "Any" && (
            <>
              <div>Team:</div>
              <div>{jobSheet.team}</div>
            </>
          )}
          {report.query.job === "Any" && (
            <>
              <div>Job:</div>
              <div>{jobSheet.job}</div>
            </>
          )}
          {report.query.day === "Any" && (
            <>
              <div>Day:</div>
              <div>{new Date(jobSheet.day).toLocaleDateString("en-gb")}</div>
            </>
          )}
        </div>
      )}
      {renderedSlots}
    </div>
  );

  return (
    <div>
      <FlexCentered col>
        <div className="bg-gbfBlue-100 w-full max-w-3xl mb-56">
          <div className="grid grid-cols-[1fr_auto] px-2 py-4 bg-gbfBlue-900 text-gbfCream-50 ">
            <div className="grid grid-cols-[1fr_auto] gap-x-4 w-fit">
              <Heading secondary className="col-span-2 text-gbfBlue-100">
                Job Sheet Report
              </Heading>
              <div>Team:</div>
              <div>{report.query.team}</div>
              <div>Job:</div>
              <div>{report.query.job}</div>
              <div>Day:</div>
              <div>{new Date(report.query.day).toLocaleDateString()}</div>
              <div>Page:</div>
              <div>
                {currentIndex + 1} of {report.jobSheets.length}
              </div>
            </div>
            <FlexCentered row className="w-fit gap-x-2 h-fit">
              <JobSheetsPDF report={report} />
              <JobSheetsXLSX report={report} />
            </FlexCentered>
          </div>
          <div className="px-2 py-8">{renderedPage}</div>
          {report.jobSheets.length > 1 && (
            <div className="bg-gbfBlue-900 text-gbfCream-50 py-4 text-2xl">
              <FlexCentered row className="gap-x-2">
                <BsChevronLeft
                  className={classNames(
                    "hover:text-gbfGreen-200 cursor-pointer",
                    {
                      "pointer-events-none opacity-50": currentIndex === 0,
                    }
                  )}
                  onClick={() => setCurrentIndex((curr) => curr - 1)}
                />
                <BsChevronRight
                  className={classNames(
                    "hover:text-gbfGreen-200 cursor-pointer",
                    {
                      "pointer-events-none opacity-50":
                        currentIndex === report.jobSheets.length - 1,
                    }
                  )}
                  onClick={() => setCurrentIndex((curr) => curr + 1)}
                />
              </FlexCentered>
            </div>
          )}
        </div>
      </FlexCentered>
    </div>
  );
}
export default JobSheetsReportView;
