import { useGetActiveRota, useGetTeams } from "../../../../api";
import getApiStatusPage from "../../../APIStatusPage";
import Page from "../../../../Containers/Page";
import Teams from "./Teams";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import Provider from "../../../../providers/EditRotaContext";
import LoadingOverlay from "./LoadingOverlay";

function EditShiftsPage() {
  const getActiveRotaApi = useGetActiveRota();

  const rota = getActiveRotaApi?.data?.rota;
  const getTeamsApi = useGetTeams({
    rotaRef: rota?._id,
  });

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getActiveRotaApi,
        type: "Active rota",
        dataRequired: true,
        initialLoadOnly: true,
      },
      {
        api: getTeamsApi,
        type: "Teams",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const teams = getTeamsApi?.data?.teams;

  return (
    <Provider rota={rota}>
      <Page title="Edit shifts" className="pb-56">
        <LoadingOverlay />
        <FlexCentered col>
          <Heading primary className="pt-4 pb-8">
            Edit Shifts
          </Heading>
          <Teams teams={teams} />
        </FlexCentered>
      </Page>
    </Provider>
  );
}
export default EditShiftsPage;
