import colours from "./colours";
import features from "./features";
import locations from "./locations";
import habitats from "./habitats";
import waterNouns from "./waterNouns";
import landNouns from "./landNouns";

const components = {
  colours: {
    label: "colours",
    words: colours,
    validNext: ["features", "habitats"],
    validFinal: ["landNouns", "waterNouns"],
  },
  locations: {
    label: "locations",
    words: locations,
    validNext: ["colours", "habitats"],
  },
  features: {
    label: "features",
    words: features,
    validFinal: ["landNouns", "waterNouns"],
  },
  habitats: {
    label: "habitats",
    words: habitats,
    validFinal: ["landNouns"],
  },
  waterNouns: {
    label: "waterNouns",
    words: waterNouns,
  },
  landNouns: {
    label: "landNouns",
    words: landNouns,
  },
};

function getRandomWord(sections) {
  let totalWords = 0;
  for (let i = 0; i < sections.length; i++) {
    totalWords += components[sections[i]]?.words.length || 0;
  }
  const index = Math.floor(Math.random() * totalWords);
  let floor = 0;
  let section;
  for (let i = 0; i < sections.length; i++) {
    const currentSection = components[sections[i]];
    if (index < currentSection.words.length + floor) {
      section = currentSection;
      break;
    } else floor += currentSection.words.length;
  }
  const word = section.words[index - floor];

  return [section, word];
}

function randomBirdName() {
  const startingPoints = ["colours", "locations"];

  let bird = "";
  let [component, word] = getRandomWord(startingPoints);
  bird += word;
  [component, word] = getRandomWord(component.validNext);
  bird += ` ${word}`;
  [component, word] = getRandomWord(component.validFinal);
  bird += ` ${word}`;

  return bird;
}

export default randomBirdName;
