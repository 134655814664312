import classNames from "classnames";
import FlexCentered from "../../Containers/FlexCentered";

function Slot({ slot, position, isActive, setActiveSlot }) {
  const getBgColour = (fillPercentage) => {
    if (fillPercentage > 100) return "rgb(242, 204, 143)";

    const emptyRed = 202;
    const emptyGreen = 110;
    const emptyBlue = 86;

    const redDiff = 129 - emptyRed;
    const greenDiff = 178 - emptyGreen;
    const blueDiff = 154 - emptyBlue;

    const multiplier = Math.min(fillPercentage, 100) / 100;

    const red = emptyRed + redDiff * multiplier;
    const green = emptyGreen + greenDiff * multiplier;
    const blue = emptyBlue + blueDiff * multiplier;
    return `rgba(${red},${green},${blue},0.9)`;
  };

  return (
    <div
      data-id={slot._id}
      className={classNames(
        "absolute w-full bg-gbfGreen-500 h-full text-center border border-x-1 border-gbfGreen-900 cursor-pointer",
        {
          "z-50 scale-y-105 shadow-md shadow-gbfGreen-1200": isActive,
        }
      )}
      style={{
        ...position,
        backgroundColor: getBgColour(slot.filledPercentage),
      }}
      onClick={() => {
        if (isActive) setActiveSlot(null);
        else {
          setActiveSlot(slot);
        }
      }}
    >
      <FlexCentered col className="w-full h-full pointer-events-none">
        {slot.filledPercentage}%
      </FlexCentered>
    </div>
  );
}
export default Slot;
