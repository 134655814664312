import { useGetAllUsers } from "../../../api";
import useQuery from "../../../hooks/useQuery";
import Page from "../../../Containers/Page";
import { useEffect } from "react";
import Table from "../../../Components/Table/Table";
import TableContext from "../../../Components/Table/TableContext";
import getApiStatusPage from "../../APIStatusPage";

function UsersPage() {
  const fields =
    "givenName,familyName,email,slug,credentialsCommunicated,hasAllocations";
  const defaultQuery = {
    fields,
  };

  const [query, isStale, setIsStale, setQuery, Filter] = useQuery(defaultQuery);

  const getAllUsersApi = useGetAllUsers({
    params: query,
  });

  useEffect(() => {
    if (!isStale) {
      getAllUsersApi.refetch();
      setIsStale(true);
    }
  }, [getAllUsersApi, isStale, query, setIsStale]);

  const users = getAllUsersApi?.data?.users;

  const tableConfig = {
    resizeableColumns: { fixed: true },
    movableColumns: true,
    rowLink: (row) => `/admin/users/${row.slug}`,
    showControls: true,
    showPagination: true,
    columns: [
      {
        label: "Name",
        fieldNames: ["familyName", "givenName"],
        fieldNamesLabels: ["Family Name", "Given Name"],
        filterType: "String",
        searchFieldNames: ["fullName"],
        row: (row) => `${row.familyName}, ${row.givenName} `,
        width: "sm",
        searchable: true,
      },
      {
        label: "Email",
        fieldNames: ["email"],
        fieldNamesLabels: ["Email"],
        filterType: "String",
        searchFieldNames: ["email"],
        row: (row) => `${row.email}`,
        width: "sm",
        searchable: true,
      },
      {
        label: "Credentials Communicated",
        fieldNames: ["credentialsCommunicated"],
        fieldNamesLabels: ["Credentials Communicated"],
        filterType: "Enum",
        options: [
          { label: "Pending", id: "Pending", value: "Pending" },
          { label: "Communicated", id: "Communicated", value: "Communicated" },
          { label: "Failed", id: "Failed", value: "Failed" },
        ],
        row: (row) => `${row.credentialsCommunicated}`,
        width: "sm",
        visible: false,
      },
      {
        label: "Has Allocations",
        fieldNames: ["hasAllocations"],
        fieldNamesLabels: ["Has Allocations"],
        filterType: "Enum",
        options: [
          { label: "False", id: "False", value: false },
          { label: "True", id: "True", value: true },
        ],
        row: (row) => (row.hasAllocations ? "Yes" : "No"),
        width: "sm",
        visible: false,
      },
    ],
  };

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getAllUsersApi,
        type: "Users",
        dataRequired: true,
        initialLoadOnly: !!users,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page
      title="Users"
      allowHorizontalScroll
      className="min-h-screen pb-56 w-full"
    >
      <TableContext
        config={tableConfig}
        api={getAllUsersApi}
        url="admin/users"
        query={query}
        setQuery={setQuery}
        Filter={Filter}
      >
        <Table data={users} />
      </TableContext>
    </Page>
  );
}
export default UsersPage;
