import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetRotaReport() {
  const queryApi = useQuery({
    queryKey: ["teams", "jobs", "slots", "allocations"],
    queryFn: async () =>
      request({
        req: {
          url: "rota/active-rota/rota-report",
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetActiveRota() {
  const queryApi = useQuery({
    queryKey: ["rota"],
    queryFn: async () =>
      request({
        req: {
          url: "rota/active-rota",
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetTeamsJobsAndDays() {
  const queryApi = useQuery({
    queryKey: ["teams", "jobs", "slots"],

    refetchOnWindowFocus: false,
    queryFn: async () =>
      request({
        req: {
          url: "rota/teams-jobs-days",
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetTeams({ rotaRef }) {
  const queryApi = useQuery({
    queryKey: ["teams"],
    enabled: !!rotaRef,
    queryFn: async () =>
      request({
        req: {
          url: `rota/${rotaRef}/teams`,
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetJobsByTeam({ rotaRef, teamRef }) {
  const queryApi = useQuery({
    queryKey: ["jobs", `jobs-${teamRef?.toString()}`],
    enabled: !!rotaRef && !!teamRef,
    queryFn: async () =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs`,
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetSlotsByJob({ rotaRef, teamRef, jobRef }) {
  const queryApi = useQuery({
    queryKey: ["slots", `slot-${jobRef?.toString()}`],
    enabled: !!rotaRef && !!teamRef && !!jobRef,
    queryFn: async () =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}/slots`,
          method: "GET",
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useCreateRota() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `rota`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["rota"]);
    },
  });
  return queryApi;
}

function useCreateTeam() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["teams"]);
    },
  });
  return queryApi;
}

function useCreateJob() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`jobs`]);
    },
  });
  return queryApi;
}

function useCreateSlot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, jobRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}/slots`,
          method: "POST",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`slots`]);
    },
  });
  return queryApi;
}

function useEditRota() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`rota`]);
    },
  });
  return queryApi;
}

function useEditTeam() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`teams`]);
    },
  });
  return queryApi;
}

function useEditJob() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, jobRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`jobs`]);
    },
  });
  return queryApi;
}

function useEditSlot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, jobRef, slotRef, body }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}/slots/${slotRef}`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`slots`]);
    },
  });
  return queryApi;
}

function useDeleteTeam() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}`,
          method: "DELETE",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`teams`]);
    },
  });
  return queryApi;
}

function useDeleteJob() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, jobRef }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}`,
          method: "DELETE",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`jobs`]);
    },
  });
  return queryApi;
}

function useDeleteSlot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ rotaRef, teamRef, jobRef, slotRef }) =>
      request({
        req: {
          url: `rota/${rotaRef}/teams/${teamRef}/jobs/${jobRef}/slots/${slotRef}`,
          method: "DELETE",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`slots`]);
    },
  });
  return queryApi;
}

export {
  useCreateRota,
  useGetActiveRota,
  useCreateTeam,
  useGetTeams,
  useCreateJob,
  useCreateSlot,
  useGetJobsByTeam,
  useGetTeamsJobsAndDays,
  useGetSlotsByJob,
  useEditRota,
  useEditTeam,
  useEditSlot,
  useEditJob,
  useDeleteTeam,
  useDeleteJob,
  useDeleteSlot,
  useGetRotaReport,
};
