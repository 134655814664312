import { useForm } from "react-hook-form";
import { useRegister } from "../api";
import FlexCentered from "../Containers/FlexCentered";
import Button from "../Components/Button";
import Heading from "../Components/Heading";
import Label from "../Components/Label";
import Input from "../Components/Input";
import RenderedErrors from "../Components/RenderedErrors";
import P from "../Components/P";
import Link from "../Components/Link";

function RegistrationForm() {
  const registerAdminApi = useRegister();

  const form = useForm();
  const errors = form.formState.errors;
  let apiError = registerAdminApi.error;

  const { register } = form;

  const givenNameProps = register("givenName", {
    required: "Enter your given name",
    maxLength: {
      value: 18,
      message: "The given name field cannot exceed 18 characters. ",
    },
  });
  const familyNameProps = register("familyName", {
    required: "Enter your family name",
    maxLength: {
      value: 18,
      message: "The family name field cannot exceed 18 characters. ",
    },
  });

  const emailProps = register("email", {
    required: "Enter your email address",
    pattern: {
      value:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      message: "Invalid email",
    },
  });

  const passwordProps = register("password", {
    required: "Enter a password",
    minLength: {
      value: 8,
      message: "Your password must be at least 8 characters long",
    },
    maxLength: {
      value: 36,
      message: "Your password cannot exceed 36 characters",
    },
  });

  const confirmPasswordProps = register("confirmPassword", {
    required: "Confirm password required",
    validate: (value, formValues) => {
      return formValues.password === value || "The passwords do not match";
    },
  });

  const handleSubmit = (data) => {
    registerAdminApi.mutate({ body: data });
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      noValidate
      className="pt-[5vh] pb-20"
    >
      <FlexCentered col>
        <Heading primary className="text-gbfBlue-500 mb-6">
          Register as an Admin
        </Heading>
        <P className="mb-8">
          Note: If you have not been invited to register as an admin please
          return to the{" "}
          <Link to="/" className="underline">
            volunteer area
          </Link>
        </P>
        <Label htmlFor="givenName" className="mb-2">
          Given Name
        </Label>
        <Input
          type="text"
          id="givenName"
          className="w-[20rem] max-w-full mb-4"
          {...givenNameProps}
          ref={null}
          innerRef={givenNameProps.ref}
          error={errors.givenName}
        />
        <Label htmlFor="familyName" className="mb-2">
          Family Name
        </Label>
        <Input
          type="text"
          id="familyName"
          className="w-[20rem] max-w-full mb-8"
          {...familyNameProps}
          ref={null}
          innerRef={familyNameProps.ref}
          error={errors.familyName}
        />
        <Label htmlFor="email" className="mb-2">
          Email
        </Label>
        <Input
          type="email"
          id="email"
          className="w-[20rem] max-w-full mb-8"
          {...emailProps}
          ref={null}
          innerRef={emailProps.ref}
          error={errors.email}
        />

        <Label htmlFor="password" className="mb-2">
          Password
        </Label>
        <Input
          type="password"
          id="password"
          className="w-[20rem] max-w-full mb-4"
          {...passwordProps}
          ref={null}
          innerRef={passwordProps.ref}
          error={errors.password}
        />

        <Label htmlFor="confirmPassword" className="mb-2">
          Confirm Password
        </Label>
        <Input
          type="password"
          id="confirmPassword"
          className="w-[20rem] max-w-full mb-8"
          {...confirmPasswordProps}
          ref={null}
          innerRef={confirmPasswordProps.ref}
          error={errors.confirmPassword}
        />

        <Button primary type="submit" loading={registerAdminApi.isLoading}>
          Register
        </Button>

        {form.formState.isSubmitted && (
          <RenderedErrors errors={errors} apiError={apiError} />
        )}
      </FlexCentered>
    </form>
  );
}

export default RegistrationForm;
