import { useViewAndEditShiftsContext } from "../../providers/ViewAndEditShiftsContext";
import Heading from "../../Components/Heading";
import Loading from "../../Components/Loading";
import P from "../../Components/P";
import FlexCentered from "../../Containers/FlexCentered";
import classNames from "classnames";

function RotaView({ userRota, api, isAdmin }) {
  const { handleSetEdit, isLoading } = useViewAndEditShiftsContext();

  const formatDate = (date) => {
    const sourceDate = new Date(date);
    return sourceDate.toLocaleTimeString("en-GB", {
      timeZone: "Europe/London",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  let renderedShifts;
  if (userRota) {
    renderedShifts = userRota.map((day) => {
      const renderedSlots = day.slots.map((slot) => {
        return (
          <div
            key={slot.ids.slot}
            className="relative grid grid-cols-[10rem_auto] w-full gap-x-4 bg-gbfGreen-1000 text-gbfCream-50 rounded px-2 py-1 hover:scale-[101%] hover:shadow-md"
          >
            <div>{slot.job}</div>
            <div>
              {formatDate(slot.start)}-{formatDate(slot.end)}
            </div>
            <div
              className={classNames(
                "absolute inset-y-0 right-0 bg-gbfBlue-500 hover:bg-gbfBlue-900 cursor-pointer px-2",
                {
                  "opacity-50 pointer-events-none": isLoading,
                }
              )}
              onClick={() => handleSetEdit(slot.ids)}
            >
              <FlexCentered col className="w-full h-full">
                Edit
              </FlexCentered>
            </div>
          </div>
        );
      });
      return (
        <div key={day.date}>
          <div className="border-b border-gbfBlue-300 pb-1">
            <Heading tertiary>{day.date}</Heading>
          </div>
          <div className="flex flex-col gap-y-1 py-2">{renderedSlots}</div>
        </div>
      );
    });
  }

  return (
    <div className="mt-2 w-full">
      <div className="w-full bg-gbfBlue-500 py-2 mt-8">
        <Heading secondary className="text-gbfCream-50 text-center">
          Saved Shifts
        </Heading>
      </div>
      <div className="w-full bg-gbfBlue-100 py-2 px-2 py-4 mb-56">
        {api.isFetching && <Loading />}
        {!api.isFetching && renderedShifts.length > 0 && (
          <div className="flex flex-col gap-y-4">{renderedShifts}</div>
        )}
        {!api.isFetching && renderedShifts.length === 0 && (
          <div className="">
            {!isAdmin && (
              <>
                <P className="mb-2">Your rota is empty.</P>
                <P className="mb-2">
                  Please click the "Add Shift" button above to select your
                  shifts.
                </P>
              </>
            )}
            {isAdmin && <P className="mb-2">The rota is empty</P>}
          </div>
        )}
      </div>
    </div>
  );
}
export default RotaView;
