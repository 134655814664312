import classNames from "classnames";

function getTeamAndJobsList({
  rota,
  rowHeight,
  visibleBlockRange,
  latestDate,
  selectedTeam,
}) {
  const rowIndex = {};
  let currentIndex = 0;
  const rows = [];

  //Iterate through days. Use the visible block range to determine the first and last
  //visible dates
  let startDate;
  const visibleDays = [];
  let endDate;
  let i = 0;
  while ((!startDate || !endDate) && rota.days[i]) {
    const day = rota.days[i];
    i++;
    if (
      !startDate &&
      day.earliestBlock - 1 <= visibleBlockRange[0] &&
      day.lastBlock - 1 >= visibleBlockRange[0]
    ) {
      startDate = day;
      visibleDays.push(day);
    } else if (startDate) visibleDays.push(day);

    if (
      !endDate &&
      day.earliestBlock <= visibleBlockRange[1] &&
      day.lastBlock >= visibleBlockRange[1]
    ) {
      endDate = day;
    }
  }

  //Store a set of teams and jobs with shifts during the visible days
  if (!endDate) endDate = latestDate;
  let visibleTeams = {};
  let visibleJobs;
  visibleDays.forEach((day) => {
    visibleTeams = { ...visibleTeams, ...day.teams };
    visibleJobs = { ...visibleJobs, ...day.jobs };
  });

  //Get position of a given element
  const getPosition = (i) => {
    return { left: 0, top: `${rowHeight * i}px` };
  };

  //Generic classes for job and team elements
  const rowClasses = (type) =>
    classNames("absolute truncate w-full h-full", {
      "pl-1 bg-gbfBlue-200 text-[14px]": type === "job",
      "pl-1 bg-gbfBlue-600 text-gbfCream-50 text-[16px]": type === "team",
    });

  //Step through each team
  rota.teams.forEach((team) => {
    //If team is in visible range, push element to array
    if (
      !visibleTeams[team._id] ||
      (selectedTeam && team._id.toString() !== selectedTeam?._id.toString())
    )
      return;
    rows.push(
      <div
        key={team._id}
        className={rowClasses("team")}
        style={getPosition(currentIndex)}
        title={team.name}
      >
        {team.name}
      </div>
    );
    rowIndex[team._id] = currentIndex;
    currentIndex++;

    //Step through each job in team object
    team.teamJobs.forEach((job) => {
      //If job is in visible range, push element to array
      if (!visibleJobs[job._id]) return;
      rows.push(
        <div
          key={job._id}
          className={rowClasses("job")}
          style={getPosition(currentIndex)}
          title={job.name}
        >
          {job.name}
        </div>
      );
      rowIndex[job._id] = currentIndex;
      currentIndex++;
    });
  });

  return [rows, rowIndex];
}
export default getTeamAndJobsList;
