import classNames from "classnames";

function Heading({
  primary,
  secondary,
  tertiary,
  children,
  className,
  ...props
}) {
  if (primary) {
    return (
      <h1
        className={classNames(
          "text-3xl",
          {
            "font-light tracking-wide": !(className || "").includes("font-"),
            "text-gbfBlue-700": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h1>
    );
  }

  if (secondary) {
    return (
      <h2
        className={classNames(
          "text-lg",
          {
            "font-light tracking-wide": !(className || "").includes("font-"),
            "text-gbfBlue-700": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h2>
    );
  }

  if (tertiary) {
    return (
      <h3
        className={classNames(
          "text-md",
          {
            "font-semibold tracking-wide": !(className || "").includes("font-"),
            "text-gbfBlue-700": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h3>
    );
  }
}

export default Heading;
