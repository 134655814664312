import { useGetCurrentUser } from "../../api";

import EmailConfirmationForm from "../../forms/EmailConfirmationForm";
import Page from "../../Containers/Page";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import Card from "../../Containers/Card";

function ConfirmEmail() {
  const currentUserAPI = useGetCurrentUser();
  const currentUser = currentUserAPI.data.user;
  if (!currentUser) return <div>Loading</div>;

  return (
    <Page title="Admin - Dashboard">
      <FlexCentered col className="pt-8">
        <Heading secondary>{`Welcome ${currentUser.givenName}`}</Heading>
        <Card className="mt-8">
          <FlexCentered col className="mt-4">
            <Heading tertiary className="mb-6">
              Email Confirmation
            </Heading>
            <P className="mb-8">
              A confirmation code has been sent to{" "}
              <span className="font-semibold">{currentUser.email}</span>. Enter
              the code below to confirm your email address.
            </P>
            <EmailConfirmationForm currentUser={currentUser} />
          </FlexCentered>
        </Card>
      </FlexCentered>
    </Page>
  );
}

export default ConfirmEmail;
