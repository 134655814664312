import { useEffect, useMemo } from "react";
import Dropdown from "../../../Components/Dropdown";
import Heading from "../../../Components/Heading";
import FlexCentered from "../../../Containers/FlexCentered";
import Button from "../../../Components/Button";

function Filters({
  teams,
  days,
  activeTeam,
  setActiveTeam,
  activeJob,
  setActiveJob,
  activeDay,
  setActiveDay,
  onGenerateQuery,
}) {
  const dayOptions = useMemo(() => {
    if (activeJob) return activeJob.days;
    if (activeTeam) return activeTeam.days;
    return days;
  }, [activeJob, activeTeam, days]);

  useEffect(() => {
    if (activeJob || activeTeam) {
      let validDay = false;
      if (activeJob && activeJob.days.some((day) => day === activeDay)) {
        validDay = true;
      }
      if (
        !validDay &&
        !activeJob &&
        activeTeam &&
        activeTeam.days.some((day) => day === activeDay)
      ) {
        validDay = true;
      }
      if (!validDay) setActiveDay(null);
    }
  }, [activeDay, activeJob, activeTeam, setActiveDay]);

  return (
    <FlexCentered col>
      <Heading tertiary className="mb-4">
        Filters
      </Heading>
      <div className="mb-2">
        <Dropdown
          value={activeTeam}
          onChange={(value) => {
            setActiveTeam(value);
            if (value && value.jobs.length === 1) {
              setActiveJob(value.jobs[0]);
            } else setActiveJob(null);
          }}
          config={{
            data: teams,
            getLabel: (team) => team.name,
          }}
          placeholder="Team"
        />
      </div>
      <div className="mb-2">
        <Dropdown
          value={activeJob}
          onChange={setActiveJob}
          config={{
            data: activeTeam?.jobs,
            getLabel: (job) => job.name,
          }}
          placeholder="Job"
          disabled={!activeTeam || activeTeam.jobs.length === 1}
        />
      </div>
      <div className="mb-4">
        <Dropdown
          value={activeDay}
          onChange={setActiveDay}
          config={{
            data: dayOptions,
            getLabel: (day) => day,
            getKey: (day) => day,
          }}
          placeholder="Day"
        />
      </div>
      <Button secondary onClick={onGenerateQuery}>
        Generate
      </Button>
    </FlexCentered>
  );
}
export default Filters;
