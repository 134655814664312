import logo from "../../assets/logo.png";

function Logo() {
  return (
    <div className="w-max pl-[3px] pr-[6px] pt-[5px] pb-[7px] bg-gbfYellow-50 flex flex-col items-center justify center rounded-full">
      <img
        src={logo}
        alt="Global Bird Fair logo - silhouette of a flying bird"
        className="w-[50px] transition-all duration-300 ease-in-out"
      />
    </div>
  );
}

export default Logo;
