import { useRef } from "react";
import { useTableContext } from "../TableContext";
import classNames from "classnames";

function ColumnIndicator({ id }) {
  const { activeTab, tableHeight, resizeColumns, moveColumnTo } =
    useTableContext();
  const isIndicatingMove = moveColumnTo === id;
  const isActive = activeTab === id;

  const tabRef = useRef(null);
  const classes = classNames(
    "block absolute  w-1 right-0 top-0 z-20 border-r-2  hover:opacity-100 min-w-0 headerControl",
    {
      "border-gbfBlue-500 opacity-100 cursor-auto": isActive,
      "cursor-ew-resize border-gbfGreen-900": !isActive,
      "hover:border-gbfBlue-500": !isIndicatingMove,
      "border-main opacity-100": isIndicatingMove,
      "opacity-0": !isActive && !isIndicatingMove,
    }
  );
  return (
    <div
      ref={tabRef}
      data-id={id}
      style={{ height: tableHeight }}
      onMouseDown={() => resizeColumns(tabRef)}
      className={classes}
    />
  );
}

export default ColumnIndicator;
