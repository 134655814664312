import Page from "../../../Containers/Page";
import AddUserForm from "../../../forms/users/AddUserForm";
import Heading from "../../../Components/Heading";
import FlexCentered from "../../../Containers/FlexCentered";

function AddUserPage() {
  return (
    <Page title="Add User">
      <FlexCentered col className="pt-4">
        <Heading primary className="mb-12">
          Add User
        </Heading>
        <AddUserForm />
      </FlexCentered>
    </Page>
  );
}
export default AddUserPage;
