import { useForm } from "react-hook-form";
import FlexCentered from "../Containers/FlexCentered";
import Input from "../Components/Input";
import Label from "../Components/Label";
import Button from "../Components/Button";
import RenderedErrors from "../Components/RenderedErrors";

function EditAdminForm({ user, updateMeApi }) {
  const form = useForm({
    values: user,
  });
  const errors = form.formState.errors;
  const { register } = form;
  const givenNameProps = register("givenName", {
    required: "Given name required",
  });
  const familyNameProps = register("familyName", {
    required: "Family name required",
  });

  const handleSubmit = (data) => {
    updateMeApi.mutate({
      body: data,
    });
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
      <FlexCentered col>
        <Label htmlFor="givenName" className="mb-2">
          Given Name
        </Label>
        <Input
          type="text"
          id="givenName"
          className="w-[20rem] max-w-full mb-4"
          {...givenNameProps}
          ref={null}
          innerRef={givenNameProps.ref}
          error={errors.givenName}
        />
        <Label htmlFor="familyName" className="mb-2">
          Family Name
        </Label>
        <Input
          type="text"
          id="familyName"
          className="w-[20rem] max-w-full mb-4"
          {...familyNameProps}
          ref={null}
          innerRef={familyNameProps.ref}
          error={errors.familyName}
        />

        <Button
          primary
          type="submit"
          loading={updateMeApi.isLoading}
          disabled={!form.formState.isDirty}
        >
          Save
        </Button>

        <RenderedErrors
          errors={errors}
          apiError={updateMeApi.error}
          className="bg-gbfCream-50"
        />
      </FlexCentered>
    </form>
  );
}
export default EditAdminForm;
