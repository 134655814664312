import { useState, createContext, useContext } from "react";

const EditRotaContext = createContext();

const Provider = function ({ rota, children }) {
  const [activeTeam, setActiveTeam] = useState(null);
  const [activeJob, setActiveJob] = useState(null);
  const [activeSlot, setActiveSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const model = {
    rota,
    activeTeam,
    setActiveTeam,
    activeJob,
    setActiveJob,
    activeSlot,
    setActiveSlot,
    isLoading,
    setIsLoading,
  };

  return (
    <EditRotaContext.Provider value={model}>
      {children}
    </EditRotaContext.Provider>
  );
};

const useEditRotaContext = function () {
  return useContext(EditRotaContext);
};

export { useEditRotaContext };
export default Provider;
