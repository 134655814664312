import { useEffect } from "react";
import { useGetCurrentUser, useUpdateMe } from "../../api";
import { useToastContext } from "../../providers/toastContext";
import Heading from "../../Components/Heading";
import FlexCentered from "../../Containers/FlexCentered";
import Page from "../../Containers/Page";
import EditAdminForm from "../../forms/EditAdminForm";
import getApiStatusPage from "../APIStatusPage";
import UpdatePasswordForm from "../../forms/UpdatePasswordForm";

function Profile() {
  const getUserApi = useGetCurrentUser();
  const user = getUserApi?.data?.user;
  const updateMeApi = useUpdateMe();

  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (updateMeApi.isSuccess)
      createSuccessToast("Details updated successfully");
  }, [createSuccessToast, updateMeApi.isSuccess]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;
  if (!user) return;

  return (
    <Page title="Profile">
      <FlexCentered col className="pt-12">
        <Heading primary className="mb-8">
          My Details
        </Heading>
        <EditAdminForm user={user} updateMeApi={updateMeApi} />
        <Heading primary className="mb-8 mt-12">
          Update Password
        </Heading>
        <UpdatePasswordForm />
      </FlexCentered>
    </Page>
  );
}

export default Profile;
