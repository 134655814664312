import { useLocation } from "react-router-dom";
import { Fragment, useEffect, useRef } from "react";
import Link from "../../Components/Link";
import classNames from "classnames";

function Breadcrumbs() {
  const location = useLocation();
  const BreadcrumbsRef = useRef(null);

  const getColourValues = () => {
    const wrapper = document.getElementById("wrapper");
    let bg = "#fff";
    if (wrapper) bg = window.getComputedStyle(wrapper).backgroundColor;
    let colourValues = bg
      .match(/\d\d\d/g)
      ?.reduce((sum, el) => (sum += +el), 0);

    let mode = "dark";
    if (colourValues > Math.floor((255 * 3) / 2)) mode = "light";
    return [bg, mode];
  };

  useEffect(() => {
    if (!BreadcrumbsRef.current) return;
    const [bg, mode] = getColourValues();
    BreadcrumbsRef.current.style.backgroundColor = bg;
    BreadcrumbsRef.current.style.color =
      mode === "light" ? "rgb(49 51 73)" : "rgb(236 236 239)";
  }, [location]);

  const [bg, mode] = getColourValues();

  const pathEls = location.pathname.split("/");
  const formattedPathEls = [];
  const defaultCasing = (el) => {
    let path = el.split("-");
    let formattedPath = [];
    path.forEach((el) => {
      formattedPath.push(`${el[0].toUpperCase()}${el.slice(1).toLowerCase()}`);
    });
    return formattedPath.join(" ");
  };
  let pathTrace = "";
  pathEls.forEach((el, i) => {
    if (!el) return;
    pathTrace += `${el}/`;
    formattedPathEls.push({
      label: defaultCasing(el),
      pathname: pathTrace,
    });
  });

  if (formattedPathEls.length < 2) return undefined;

  let renderedPath = formattedPathEls.map((el, i) => {
    const isFinalEl = i === formattedPathEls.length - 1;
    return (
      <Fragment key={el.label}>
        <Link
          to={`/${el.pathname.slice(0, -1)}`}
          className={classNames({
            "pointer-events-none cursor-default opacity-90": isFinalEl,
            underline: !isFinalEl,
          })}
        >
          {el.label}
        </Link>
        {!isFinalEl && <div>/</div>}
      </Fragment>
    );
  });

  return (
    <div
      ref={BreadcrumbsRef}
      className="flex flex-row items-center py-2 px-4"
      style={{
        backgroundColor: bg,
        color: mode === "light" ? "rgb(49 51 73)" : "rgb(252 251 245)",
      }}
    >
      <div className="flex flex-row items-center gap-2">{renderedPath}</div>
    </div>
  );
}

export default Breadcrumbs;
