const colours = [
  "Amethyst",
  "Azure",
  "Black",
  "Blaze",
  "Blue",
  "Bronze",
  "Brown",
  "Cerulean",
  "Chestnut",
  "Chocolate",
  "Cinnamon",
  "Citrine",
  "Copper",
  "Crimson",
  "Dusky",
  "Elegant",
  "Emerald",
  "Festive",
  "Fire",
  "Glittering",
  "Glossy",
  "Golden",
  "Gorgeous",
  "Green",
  "Lemon",
  "Ochre",
  "Olive",
  "Orange",
  "Pale",
  "Pied",
  "Pink",
  "Plum",
  "Purplish",
  "Rose",
  "Ruby",
  "Rufous",
  "Saffron",
  "Scarlet",
  "Sooty",
  "Splendid",
  "Yellow",
  "Velvet",
  "Violet",
  "Zebra",
];

export default colours;
