import Page from "../../Containers/Page";
import ResetPasswordForm from "../../forms/ResetPasswordForm";

function ResetPassword() {
  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  return (
    <Page title="reset-password">
      <ResetPasswordForm />
    </Page>
  );
}

export default ResetPassword;
