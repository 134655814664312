import { useDeleteSlot } from "../../../../api";
import FlexCentered from "../../../../Containers/FlexCentered";
import { useEditRotaContext } from "../../../../providers/EditRotaContext";
import { useToastContext } from "../../../../providers/toastContext";
import { BsPencil, BsTrash, BsXLg } from "react-icons/bs";
import EditSlot from "../../../../forms/rota/EditSlot";
import { useEffect } from "react";

function RenderedSlots({ slots }) {
  const days = {};
  const { activeSlot, setActiveSlot, setIsLoading } = useEditRotaContext();

  const deleteSlotApi = useDeleteSlot();
  const handleDelete = (slot) => {
    setActiveSlot(null);
    setIsLoading(true);
    deleteSlotApi.mutate({
      rotaRef: slot.rota,
      teamRef: slot.team,
      jobRef: slot.job,
      slotRef: slot._id,
    });
  };
  const { createSuccessToast, createErrorToast } = useToastContext();
  useEffect(() => {
    if (deleteSlotApi.isSuccess) {
      setIsLoading(false);
      createSuccessToast("Shift successfully deleted");
    }
  }, [createSuccessToast, deleteSlotApi.isSuccess, setIsLoading]);
  useEffect(() => {
    if (deleteSlotApi.error) {
      setIsLoading(false);
      createErrorToast(deleteSlotApi.error);
    }
  }, [createErrorToast, deleteSlotApi.error, setIsLoading]);

  slots.forEach((slot) => {
    const renderedSlot = (
      <div
        key={slot._id}
        className="grid grid-cols-[1fr_auto] w-full bg-gbfCream-50 odd:bg-opacity-50 "
      >
        <div className="px-4 w-full py-2">
          <div className="grid grid-cols-[1fr_auto] w-fit gap-x-4 items-center">
            <div>
              {new Date(slot.start).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -
              {new Date(slot.end).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <FlexCentered row className="gap-x-2 bg-gray-300 px-2 rounded py-1">
              <div>Max: {slot.max || "∞"}</div>
              <div>Goal: {slot.target}</div>
            </FlexCentered>
          </div>
          {activeSlot === slot && <EditSlot slot={slot} />}
        </div>
        <div>
          <FlexCentered row className="gap-x-2 p-2">
            {activeSlot !== slot && (
              <BsPencil
                onClick={() => setActiveSlot(slot)}
                className="cursor-pointer hover:text-gbfBlue-400"
              />
            )}
            {activeSlot === slot && (
              <BsXLg
                onClick={() => setActiveSlot(null)}
                className="cursor-pointer text-red-900 hover:text-gbfBlue-900"
              />
            )}
            <BsTrash
              className="cursor-pointer hover:text-red-700"
              onClick={() => handleDelete(slot)}
            />
          </FlexCentered>
        </div>
      </div>
    );
    const day = new Date(slot.start);
    day.setHours(0);
    day.setMinutes(0);

    if (days[day.getTime()]) {
      days[day.getTime()].slots.push(renderedSlot);
    } else {
      days[day.getTime()] = {
        date: day,
        slots: [renderedSlot],
      };
    }
  });

  const renderedDays = Object.keys(days).map((dayKey) => {
    const day = new Date(days[dayKey].date).toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });

    return (
      <div key={dayKey} className="w-full">
        <div className="bg-gbfBlue-500 text-gbfCream-50 shadow px-4">{day}</div>
        <FlexCentered col className="py-2 gap-y-2">
          {days[dayKey].slots}
        </FlexCentered>
      </div>
    );
  });

  return renderedDays;
}
export default RenderedSlots;
