import { useEffect, useMemo } from "react";
import Label from "../../Components/Label";
import Dropdown from "../../Components/Dropdown";
import Button from "../../Components/Button";
import { useForm, Controller } from "react-hook-form";
import { useEditSlot } from "../../api";
import FlexCentered from "../../Containers/FlexCentered";
import { useToastContext } from "../../providers/toastContext";
import { useEditRotaContext } from "../../providers/EditRotaContext";

function EditSlot({ rota, team, job, slot }) {
  const editSlotApi = useEditSlot();

  const { setActiveSlot, setIsLoading } = useEditRotaContext();
  const { createSuccessToast, createErrorToast } = useToastContext();

  const defaultMax = slot.max
    ? {
        label: `${slot.max}`,
        id: slot.max,
        value: slot.max,
      }
    : undefined;
  const defaultTarget = {
    label: `${slot.target}`,
    id: slot.target,
    value: slot.target,
  };

  const form = useForm({
    values: {
      max: defaultMax,
      target: defaultTarget,
    },
  });
  const errors = form.formState.errors;
  const { register } = form;
  register("max", {});
  register("target", {
    required: "Goal required",
  });

  const digitList = useMemo(() => {
    let list = [];
    for (let i = 1; i <= 9; i++) {
      list.push({
        label: `${i}`,
        id: i,
        value: i,
      });
    }
    return list;
  }, []);

  const handleSubmit = (data) => {
    setIsLoading(true);
    editSlotApi.mutate({
      rotaRef: slot.rota,
      teamRef: slot.team,
      jobRef: slot.job,
      slotRef: slot._id,
      body: {
        max: data?.max?.value || "unset",
        target: data?.target?.value,
      },
    });
  };

  useEffect(() => {
    if (editSlotApi.isSuccess) {
      setIsLoading(false);
      setActiveSlot(null);
      createSuccessToast("Shift edited successfully");
    }
  }, [createSuccessToast, editSlotApi.isSuccess, setActiveSlot, setIsLoading]);

  useEffect(() => {
    if (editSlotApi.error) {
      setIsLoading(false);
      createErrorToast(editSlotApi.error);
    }
  }, [createErrorToast, editSlotApi.error, setIsLoading]);

  return (
    <div>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FlexCentered row className="gap-x-2 mt-4">
          <FlexCentered col>
            <Label>Max</Label>

            <Controller
              control={form.control}
              name="max"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="max"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[4rem]"
                  config={{
                    data: digitList,
                  }}
                  error={errors.max}
                />
              )}
            />
          </FlexCentered>
          <FlexCentered col>
            <Label>Goal</Label>

            <Controller
              control={form.control}
              name="target"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="target"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[4rem]"
                  config={{
                    data: digitList,
                    options: {
                      disallowEmptyValues: true,
                    },
                  }}
                  error={errors.target}
                />
              )}
            />
          </FlexCentered>
        </FlexCentered>
        <FlexCentered col className="mt-2">
          <Button primary loading={editSlotApi.isLoading}>
            Save
          </Button>
        </FlexCentered>
      </form>
    </div>
  );
}
export default EditSlot;
