import { useForm, useWatch } from "react-hook-form";
import { AiOutlineDislike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useCreateUser } from "../../api";
import { useToastContext } from "../../providers/toastContext";

import Button from "../../Components/Button";
import Input from "../../Components/Input";
import FlexCentered from "../../Containers/FlexCentered";
import Label from "../../Components/Label";
import randomBirdName from "../../utils/randomBirdName";
import { useEffect } from "react";
import RenderedErrors from "../../Components/RenderedErrors";

function AddUserForm() {
  const createUserApi = useCreateUser();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const givenNameProps = register("givenName", {
    required: "Given name required",
  });
  const familyNameProps = register("familyName", {
    required: "Family name required",
  });
  const emailProps = register("email", {
    required: "Email required",
  });
  const userNameProps = register("userName", {
    required: "Username required",
  });

  const handleSubmit = (data) => {
    createUserApi.mutate({ body: data });
  };

  const refreshUserName = () => {
    const newUserName = randomBirdName();
    form.setValue("userName", newUserName);
  };

  const userName = useWatch({
    name: "userName",

    control: form.control,
  });

  useEffect(() => {
    if (!userName) refreshUserName();
  });

  const navigateTo = useNavigate();
  const { createSuccessToast, createErrorToast } = useToastContext();
  useEffect(() => {
    if (createUserApi.data) {
      createSuccessToast("User successfully added");
      navigateTo("/admin/users");
    }
  }, [createSuccessToast, createUserApi.data, navigateTo]);
  useEffect(() => {
    if (createUserApi.error) {
      createErrorToast(createUserApi.error);
    }
  }, [createErrorToast, createUserApi.error]);

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <FlexCentered col>
          <Label htmlFor="givenName" className="mb-2">
            Given Name
          </Label>
          <Input
            type="text"
            id="givenName"
            className="w-[20rem] max-w-full mb-4"
            {...givenNameProps}
            ref={null}
            innerRef={givenNameProps.ref}
            error={errors.givenName}
          />
          <Label htmlFor="familyName" className="mb-2">
            Family Name
          </Label>
          <Input
            type="text"
            id="familyName"
            className="w-[20rem] max-w-full mb-4"
            {...familyNameProps}
            ref={null}
            innerRef={familyNameProps.ref}
            error={errors.familyName}
          />
          <Label htmlFor="email" className="mb-2">
            Email
          </Label>
          <Input
            type="email"
            id="email"
            className="w-[20rem] max-w-full mb-4"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
            error={errors.email}
          />
          <Label className="mb-2">User Name</Label>
          <div className="w-[20rem] max-w-full mb-8 flex flex-row items-center gap-x-2 ">
            <Input
              type="text"
              id="userName"
              disabled
              className="w-full pointer-events-none disabled:bg-gbfYellow-200"
              {...userNameProps}
              ref={null}
              innerRef={userNameProps.ref}
            />
            <div className="text-xl">
              <AiOutlineDislike
                className="hover:scale-105 cursor-pointer hover:text-gbfRed-900"
                onClick={refreshUserName}
              />
            </div>
          </div>

          <Button primary type="submit" loading={createUserApi.isLoading}>
            Save
          </Button>
          <RenderedErrors
            errors={errors}
            apiError={createUserApi.error}
            className="bg-gbfCream-50"
          />
        </FlexCentered>
      </form>
    </>
  );
}

export default AddUserForm;
