import { Text, View } from "@react-pdf/renderer";
import styles from "./getStyleSheet";

function ReportData({ slots }) {
  const renderedSlots = slots.map((slot, i) => {
    const renderedVolunteers = slot.volunteers.map((vol) => {
      return (
        <View key={vol} style={styles.tableRow}>
          <View style={styles.tableCol}>
            <View
              style={{
                ...styles.tableCell,
                borderRightWidth: 1,
                borderColor: "#9ea0ad",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                }}
              >
                {vol}
              </Text>
            </View>
          </View>
          <View style={styles.tableCol}>
            <View style={styles.tableCell}></View>
          </View>
        </View>
      );
    });

    return (
      <View key={i}>
        <Text
          style={{
            fontSize: "14px",
            paddingBottom: "2px",
            borderBottom: "1px solid #313349",
          }}
        >
          {slot.start} - {slot.end}
        </Text>
        <View style={{ marginTop: "5px", marginBottom: "20px" }}>
          {renderedVolunteers.length > 0 && (
            <View style={styles.table}>
              <View
                style={{
                  ...styles.tableRow,

                  fontSize: "12px",
                  fontWeight: "normal",
                  borderBottomWidth: 1,
                  borderColor: "#9ea0ad",
                }}
              >
                <View
                  style={{
                    ...styles.tableCol,
                    borderRightWidth: 1,
                    borderColor: "#9ea0ad",
                  }}
                >
                  <View style={styles.tableCell}></View>
                </View>
                <View style={styles.tableCol}>
                  <View style={{ ...styles.tableCell, textAlign: "center" }}>
                    <Text>Present</Text>
                  </View>
                </View>
              </View>

              {renderedVolunteers}
            </View>
          )}
          {renderedVolunteers.length === 0 && (
            <Text
              style={{
                fontSize: "12px",
                margin: "5px",
                color: "#864939",
                fontWeight: "normal",
              }}
            >
              No volunteers
            </Text>
          )}
        </View>
      </View>
    );
  });

  return (
    <View style={{ marginTop: "30px", fontWeight: "light", color: "#313349" }}>
      {renderedSlots}
    </View>
  );
}
export default ReportData;
