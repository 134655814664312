import Page from "../../../Containers/Page";
import CreateRotaForm from "../../../forms/rota/CreateRotaForm";
import { useGetActiveRota } from "../../../api";
import getApiStatusPage from "../../APIStatusPage";

function EditRotaPage() {
  const getRotaApi = useGetActiveRota();

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getRotaApi,
        type: "Rota",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;
  const rota = getRotaApi?.data.rota;

  return (
    <Page title="Edit Rota">
      <CreateRotaForm rota={rota} />
    </Page>
  );
}
export default EditRotaPage;
