function getDateRangeForTeam(team, days) {
  let earliestDate;
  let latestDate;
  days.forEach((day) => {
    if (!day.teams[team._id.toString()]) return;
    if (!earliestDate) earliestDate = day;
    latestDate = day;
  });
  return [earliestDate, latestDate];
}
export default getDateRangeForTeam;
