import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEditUser, useDeleteUser } from "../../api";
import { useToastContext } from "../../providers/toastContext";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import FlexCentered from "../../Containers/FlexCentered";
import Label from "../../Components/Label";
import { useEffect } from "react";
import RenderedErrors from "../../Components/RenderedErrors";

function EditUserForm({ user }) {
  const editUserApi = useEditUser();
  const deleteUserApi = useDeleteUser();

  const form = useForm({
    values: user,
  });
  const errors = form.formState.errors;
  const { register } = form;
  const givenNameProps = register("givenName", {
    required: "Given name required",
  });
  const familyNameProps = register("familyName", {
    required: "Family name required",
  });
  const emailProps = register("email", {
    required: "Email required",
  });

  const handleSubmit = (data) => {
    editUserApi.mutate({
      userRef: user._id,
      body: data,
    });
  };

  const handleDelete = () => {
    deleteUserApi.mutate({
      userRef: user._id,
    });
  };

  const navigateTo = useNavigate();
  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (editUserApi.data) {
      const slug = editUserApi.data.data.user.slug;
      createSuccessToast("User successfully updated");
      navigateTo(`/admin/users/${slug}`);
    }
  }, [createSuccessToast, editUserApi.data, navigateTo]);
  useEffect(() => {
    if (deleteUserApi.isSuccess) {
      createSuccessToast("User successfully deleted");
      navigateTo(`/admin/users`);
    }
  }, [createSuccessToast, deleteUserApi.isSuccess, navigateTo]);

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <FlexCentered col>
          <Label htmlFor="givenName" className="mb-2">
            Given Name
          </Label>
          <Input
            type="text"
            id="givenName"
            className="w-[20rem] max-w-full mb-4"
            {...givenNameProps}
            ref={null}
            innerRef={givenNameProps.ref}
            error={errors.givenName}
          />
          <Label htmlFor="familyName" className="mb-2">
            Family Name
          </Label>
          <Input
            type="text"
            id="familyName"
            className="w-[20rem] max-w-full mb-4"
            {...familyNameProps}
            ref={null}
            innerRef={familyNameProps.ref}
            error={errors.familyName}
          />
          <Label htmlFor="email" className="mb-2">
            Email
          </Label>
          <Input
            type="email"
            id="email"
            className="w-[20rem] max-w-full mb-4"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
            error={errors.email}
          />

          <FlexCentered row className="gap-x-2 mt-4">
            <Button primary type="submit" loading={editUserApi.isLoading}>
              Save
            </Button>
            <Button
              danger
              outline
              type="button"
              loading={deleteUserApi.isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </FlexCentered>

          <RenderedErrors
            errors={errors}
            apiError={editUserApi.error || deleteUserApi.error}
            className="bg-gbfCream-50"
          />
        </FlexCentered>
      </form>
    </>
  );
}

export default EditUserForm;
