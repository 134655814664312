import { Routes, Route } from "react-router-dom";
import Navigation from "../sections/Navigation";
import AdminLogIn from "../Pages/adminAuth/AdminLogIn";
import ResetPassword from "../Pages/adminAuth/ResetPassword";
import ForgotPassword from "../Pages/adminAuth/ForgotPassword";
import Register from "../Pages/adminAuth/Register";
import NotFound from "../Pages/NotFound";

function AuthRoutes() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<AdminLogIn />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/*" element={<NotFound redirect="/admin" />} />
      </Routes>
    </>
  );
}

export default AuthRoutes;
