import { Text, View, Image } from "@react-pdf/renderer";
import logo from "../../../../assets/logo.png";

function Header({ styles, title }) {
  return (
    <View fixed style={styles.heading}>
      <View
        style={{
          backgroundColor: "#ececef",
          borderRadius: "100%",
          padding: "7px",
          marginRight: "20px",
        }}
      >
        <Image src={logo} style={{ width: "30px" }} />
      </View>
      <Text
        style={{ color: "#ececef", fontSize: "24px", fontWeight: "normal" }}
      >
        {title}
      </Text>
    </View>
  );
}
export default Header;
