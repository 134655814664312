import { useQuery } from "@tanstack/react-query";
import request from "../apiClient";

function useGetJobSheets({ params, enabled }) {
  const queryApi = useQuery({
    queryKey: ["allocations", "jobs", "teams"],
    enabled,
    refetchOnWindowFocus: false,
    queryFn: async () =>
      request({
        req: {
          url: "/reports/job-sheets",
          method: "GET",
          params,
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetContactList({ params, enabled }) {
  const queryApi = useQuery({
    queryKey: ["allocations", "jobs", "teams", "contacts"],
    enabled,
    refetchOnWindowFocus: false,
    queryFn: async () =>
      request({
        req: {
          url: "/reports/contact-list",
          method: "GET",
          params,
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

export { useGetJobSheets, useGetContactList };
