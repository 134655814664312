import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { useForgotPassword } from "../api";

import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import P from "../Components/P";
import Link from "../Components/Link";

function ForgotPasswordForm() {
  const getTokenApi = useForgotPassword();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const emailProps = register("email", {
    required: "Enter your email address",
  });

  const handleSubmit = (data) => {
    getTokenApi.mutate({ body: data });
    form.reset();
  };

  const renderedErrors = Object.keys(errors).map((key) => {
    return (
      <div key={key} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <P>{errors[key].message}</P>
      </div>
    );
  });
  if (getTokenApi.error) {
    renderedErrors.push(
      <div key={"serverError"} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <P>{getTokenApi.error}</P>
      </div>
    );
  }

  return (
    <>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className="pt-[10vh]"
      >
        <FlexCentered col>
          <Heading primary className="text-gbfBlue-500 mb-6">
            Reset Your Password
          </Heading>
          <Input
            type="email"
            id="email"
            placeholder="Email"
            className="w-[20rem] max-w-full mb-4"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
          />

          <Button primary type="submit" loading={getTokenApi.isLoading}>
            Go
          </Button>

          {renderedErrors?.length > 0 && getTokenApi.data && (
            <div className="px-2 py-2 rounded-lg mt-6 border border-gbfBlue-500 ">
              {renderedErrors}
            </div>
          )}
          {getTokenApi.data && (
            <FlexCentered row className="gap-2 mt-4">
              <AiOutlineCheckCircle className="text-gbfGreen-900" />
              <P>
                Request received, if an account with the email provided exists,
                a reset code will be sent there. The code will expire after 30
                minutes.
              </P>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default ForgotPasswordForm;
