import { BsFillPersonFill } from "react-icons/bs";

function getAdminLinks(currentUser, handleLogOut) {
  if (!currentUser || !currentUser?.isLoggedIn) return [];

  const profileLink = [
    {
      to: "/admin/rota",
      label: "Rota",
    },
    {
      to: "/admin/users",
      label: "Users",
      children: [
        {
          to: "/admin/users/add-user",
          label: "Add User",
        },
      ],
    },
    {
      to: "/admin/reports",
      label: "Reports",
    },
    {
      to: "/admin/profile",
      minLabel: "Profile",
      label: <BsFillPersonFill className="text-2xl" />,
      children: [
        {
          to: "/log-out",
          onClick: handleLogOut,
          label: "Log Out",
        },
      ],
    },
  ];
  return profileLink;
}

export default getAdminLinks;
