import { useViewAndEditShiftsContext } from "../../providers/ViewAndEditShiftsContext";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../../Components/Button";
import Label from "../../Components/Label";
import P from "../../Components/P";
import Dropdown from "../../Components/Dropdown";
import AddEditShifts from "../../forms/AddEditShifts";
import Loading from "../../Components/Loading";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import classNames from "classnames";

function EditShiftsView({ isAdmin }) {
  const {
    isEditShiftsViewOpen,
    setIsEditShiftsViewOpen,
    selectedTeam,
    setSelectedTeam,
    selectedJob,
    setSelectedJob,
    selectedDay,
    setSelectedDay,
    isLoading,
    availableShifts,
    teams,
    jobs,
    days,
  } = useViewAndEditShiftsContext();

  return (
    <FlexCentered
      col
      className={classNames("relative w-full", {
        "pointer-events-none opacity-50": isLoading,
      })}
    >
      {!isEditShiftsViewOpen && (
        <>
          {!isAdmin && availableShifts && !availableShifts.isLockedDown && (
            <P className="mb-4">{`NB: The rota will be locked down on ${availableShifts.lockdownDate}. You will not be able to update your shifts from this date.`}</P>
          )}
          {!isAdmin && availableShifts && availableShifts.isLockedDown && (
            <P className="mb-4">{`The rota has been locked down. Contact the volunteer coordinators if you need to edit your shifts`}</P>
          )}
          <Button
            primary
            onClick={() => setIsEditShiftsViewOpen(true)}
            loading={isLoading}
            disabled={availableShifts.isLockedDown && !isAdmin}
          >
            Add Shift
          </Button>
        </>
      )}
      {isEditShiftsViewOpen && !availableShifts && <Loading />}
      {isEditShiftsViewOpen && !!availableShifts && (
        <div className="relativegrid grid-cols-[auto_1fr] w-full gap-x-4 gap-y-1 bg-gbfBlue-100 px-2 py-4">
          <Heading tertiary className="mb-4 col-span-2 ">
            Edit Shifts
          </Heading>
          <Label htmlFor="selectedTeam">Team</Label>
          <Dropdown
            id="selectedTeam"
            className="mb-2 mt-1"
            value={selectedTeam}
            onChange={(value) => setSelectedTeam(value, availableShifts)}
            config={{
              data: teams,
            }}
            placeholder="Team"
          />
          <Label htmlFor="selectedJob">Job</Label>
          <Dropdown
            className="mb-2 mt-1"
            id="selectedJob"
            value={selectedJob}
            onChange={setSelectedJob}
            disabled={!jobs || jobs.length === 1}
            config={{
              data: jobs,
              options: {
                disallowEmptyValues: jobs?.length === 1,
              },
            }}
            placeholder="Job"
          />
          <Label htmlFor="selectedDay">Day</Label>
          <Dropdown
            id="selectedDay"
            className="mb-2 mt-1"
            value={selectedDay}
            onChange={setSelectedDay}
            disabled={!selectedJob}
            config={{
              data: days,
            }}
            placeholder="Day"
          />
          <div className="col-span-2 mt-6">
            <AddEditShifts isAdmin={isAdmin} />
          </div>
          <div
            className="absolute top-[5px] right-[5px] bg-gbfCream-50 text-red-900 hover:bg-red-900 hover:text-gbfCream-50 hover:scale-[105%] hover:shadow-md cursor-pointer text-lg p-1 rounded-full"
            onClick={() => setIsEditShiftsViewOpen(false)}
          >
            <AiOutlineClose />
          </div>
        </div>
      )}
    </FlexCentered>
  );
}
export default EditShiftsView;
