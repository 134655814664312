import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import getMinColWidths from "./helpers/getMinColWidths";
import TableHeader from "./components/TableHeader";
import ColumnIndicator from "./components/ColumnIndicator";
import SortIcon from "./components/SortIcon";
import Controls from "./components/Controls";
import Pagination from "./components/Pagination";
import { useTableContext } from "./TableContext";

function Table({ data, className }) {
  const navigateTo = useNavigate();

  const {
    resizeColumns,
    gridTemplateColumns,
    visibleColumns,
    moveColumIcon,
    tableRef,
    api,
    config,
  } = useTableContext();

  if (!data) return;

  ///////////////////////////////////////////////////////////////////////////////////
  // **RENDERED HEADERS
  ///////////////////////////////////////////////////////////////////////////////////

  const renderedHeaders = visibleColumns.map((column) => {
    const minWidth = getMinColWidths(column.width || "md");
    return (
      <td
        data-min-width={`${minWidth}px`}
        data-label={column.label}
        key={column.label}
        className="relative bg-gbfBlue-500 text-gbfCream-50 border-r-[0.1rem] border-b-[0.1rem] border-b-gray-400 text-center"
      >
        <TableHeader>
          {config.resizeableColumns && <ColumnIndicator id={column.label} />}
          <div className="grid grid-cols-[1fr_auto] items-center px-1">
            {column.label}
            {<SortIcon fieldNames={column.fieldNames} />}
          </div>
        </TableHeader>
      </td>
    );
  });

  ///////////////////////////////////////////////////////////////////////////////////
  // **RENDERED ROWS
  ///////////////////////////////////////////////////////////////////////////////////

  const handleLinkClick = (link) => {
    if (!link) return;
    return navigateTo(link);
  };

  const renderedRows = data.map((row) => {
    const getKey = config?.getKey ?? ((row) => row._id);

    const renderedData = visibleColumns.map((column, i) => {
      return (
        <td
          className={classNames(
            "px-2 bg-inherit truncate min-w-0 overflow-hidden",
            {
              "text-center": column.centered,
              "text-transparent": api?.isFetching,
            }
          )}
          key={`${getKey(row)}${i}`}
        >
          <span className="w-max overflow-hidden">{column.row(row)}</span>
        </td>
      );
    });
    const rowClasses = classNames(
      "contents odd:bg-gbfBlue-50 even:bg-gbfCream-50",
      {
        "cursor-pointer hover:bg-gbfBlue-100": !!config.rowLink,
      }
    );
    return (
      <tr
        className={rowClasses}
        key={getKey(row)}
        onClick={() => {
          if (!config.rowLink) return;
          handleLinkClick(config.rowLink(row));
        }}
      >
        {renderedData}
      </tr>
    );
  });

  ///////////////////////////////////////////////////////////////////////////////////
  // **RENDERED TABLE
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="pt-4">
      {config.showControls && <Controls />}
      <table
        ref={tableRef}
        className={classNames(
          "grid w-full px-2 border-collapse overflow-hidden transition-opacity duration-300",
          { "opacity-0": resizeColumns },
          className
        )}
        style={{
          gridTemplateColumns,
        }}
      >
        <thead className="contents">
          <tr className="contents">{renderedHeaders}</tr>
        </thead>
        <tbody className="contents">{renderedRows}</tbody>
        {moveColumIcon}
      </table>
      {config.showPagination && <Pagination />}
    </div>
  );
}

export default Table;
