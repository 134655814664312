import { useState, useEffect } from "react";
import { BsPlus, BsXLg } from "react-icons/bs";
import { useCreateJob } from "../../api";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import FlexCentered from "../../Containers/FlexCentered";
import RenderedErrors from "../../Components/RenderedErrors";
import Label from "../../Components/Label";
import { useEditRotaContext } from "../../providers/EditRotaContext";
import { useToastContext } from "../../providers/toastContext";

function AddJob({ forceOpen, onFocus }) {
  const [isOpen, setIsOpen] = useState(forceOpen);
  const { rota, activeTeam, setActiveJob, activeJob } = useEditRotaContext();

  const createJobApi = useCreateJob();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const nameProps = register("name", {
    required: "Team name required",
  });

  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (createJobApi.data) {
      setActiveJob(createJobApi.data?.data?.job);
      createSuccessToast("New team created");
      form.reset();
      setIsOpen(false);
    }
  }, [createSuccessToast, createJobApi.data, form, setActiveJob]);

  const handleSubmit = (data) => {
    createJobApi.mutate({
      rotaRef: rota._id,
      teamRef: activeTeam._id,
      body: data,
    });
  };

  return (
    <>
      {isOpen && (
        <form
          className="relative w-full border rounded shadow bg-gbfBlue-500 py-4 px-2"
          onSubmit={form.handleSubmit(handleSubmit)}
          noValidate
          id="add-job-form"
        >
          {!forceOpen && (
            <div className="absolute top-[5px] right-[5px] bg-gbfCream-50 rounded-full p-1 cursor-pointer hover:scale-105 hover:text-red-900">
              <BsXLg onClick={() => setIsOpen(false)} />
            </div>
          )}
          <Label htmlFor="job-name" className="text-gbfCream-50 font-normal">
            Add job
          </Label>
          <div className="flex flex-row items-stretch mt-2">
            <Input
              type="name"
              id="job-name"
              className="border-r-0 w-full"
              placeholder="Job Name"
              onFocus={onFocus}
              {...nameProps}
              ref={null}
              innerRef={nameProps.ref}
              error={errors.name}
            />
            <Button primary loading={createJobApi.isLoading}>
              Save
            </Button>
          </div>
          <FlexCentered col>
            <RenderedErrors
              errors={errors}
              apiError={createJobApi.error}
              className="bg-gbfCream-50"
            />
          </FlexCentered>
        </form>
      )}
      {!isOpen && !activeJob && (
        <FlexCentered col className="mt-4">
          <Button primary onClick={() => setIsOpen(true)}>
            Add Job
          </Button>
        </FlexCentered>
      )}
    </>
  );
}

export default AddJob;
