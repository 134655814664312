import { useForm } from "react-hook-form";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import classNames from "classnames";
import { useUserLogIn } from "../api";
import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import RenderedErrors from "../Components/RenderedErrors";

function UserLogInForm() {
  const logInApi = useUserLogIn();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  const userNameProps = register("userName", {
    required: "Username required",
  });
  const passwordProps = register("password", {
    required: "Passcode required",
  });

  const handleSubmit = (data) => {
    const formattedBody = {
      userName: data.userName.trim(),
      password: data.password.trim(),
    };
    logInApi.mutate({ body: formattedBody });
  };

  const renderedErrors = Object.keys(errors).map((key) => {
    return (
      <div
        key={key}
        className="flex flex-row items-center gap-x-2 bg-gbfCream-50"
      >
        <AiOutlineExclamationCircle className="text-red-900 " />
        <p>{errors[key].message}</p>
      </div>
    );
  });
  if (logInApi.error) {
    renderedErrors.push(
      <div key={"serverError"} className="flex flex-row items-center gap-x-2">
        <AiOutlineExclamationCircle className="text-red-900 " />
        <p>{logInApi.error}</p>
      </div>
    );
  }

  return (
    <>
      <form
        className="bg-gbfBlue-500"
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
      >
        <div className="flex flex-row items-stretch justify-stretch overflow-hidden rounded-r-lg bg-inherit max-w-full">
          <FlexCentered col>
            <Input
              type="text"
              id="userName"
              placeholder="Username"
              className={classNames(
                "rounded-tl-lg border-gray-900 active:border-gray-900 active:bg-gbfGreen-50 focus:border-gray-900 focus:bg-gbfGreen-50 md:w-[22rem] max-w-90%",
                {
                  "bg-gbfRed-50": errors.userName,
                }
              )}
              {...userNameProps}
              ref={null}
              innerRef={userNameProps.ref}
            />
            <Input
              type="password"
              id="password"
              placeholder="Passcode"
              className={classNames(
                "rounded-bl-lg border-gray-900 border-t-0 active:border-gray-900 active:bg-gbfGreen-50 focus:border-gray-900 focus:bg-gbfGreen-50 md:w-[22rem] max-w-full",
                {
                  "bg-gbfRed-50": errors.email,
                }
              )}
              {...passwordProps}
              ref={null}
              innerRef={passwordProps.ref}
            />
          </FlexCentered>
          <Button
            primary
            type="submit"
            className="h-[full] rounded-r-lg"
            loading={logInApi.isLoading}
          >
            Go
          </Button>
        </div>
        <FlexCentered col>
          <RenderedErrors
            errors={errors}
            apiError={logInApi.error}
            className="max-w-[20rem] bg-gbfCream-50"
          />
        </FlexCentered>
      </form>
    </>
  );
}

export default UserLogInForm;
