import { useState } from "react";
import getSortQuery from "./helpers/getSortQuery";
import getSearchQuery from "./helpers/getSearchQuery";
import getFilterClass from "../helpers/getFilterClass";

function useQuery(defaultQuery) {
  const [bundledQuery, setBundledQuery] = useState(defaultQuery);
  const [isStale, setIsStale] = useState(true);
  const [Filter, setFilter] = useState(getFilterClass());

  const updateQuery = (key, value) => {
    const updatedQuery = JSON.parse(JSON.stringify(bundledQuery));
    updatedQuery[key] = value;
    setBundledQuery(updatedQuery);
    setIsStale(false);
  };

  const setSort = (fieldNames) => {
    const newQuery = getSortQuery(bundledQuery, fieldNames);
    return updateQuery("sort", newQuery);
  };

  const setSearch = (searchterm, fieldNames) => {
    if (searchterm === undefined) return;

    const newQuery = getSearchQuery(searchterm, fieldNames);
    updateQuery("OR[INCLUDES]", newQuery);
  };

  const handleUpdateFilter = (newFilterObj) => {
    const updatedFilter = getFilterClass(newFilterObj);
    const updatedQuery = {
      sort: bundledQuery["sort"],
      "OR[INCLUDES]": bundledQuery["OR[INCLUDES]"],
      fields: bundledQuery.fields,
    };

    Object.keys(bundledQuery).forEach((key) => {
      if (key.startsWith("populate[")) {
        updatedQuery[key] = bundledQuery[key];
      }
    });

    Object.keys(updatedFilter?.filters || {}).forEach((fieldName) => {
      Object.keys(updatedFilter.filters[fieldName]).forEach((operator) => {
        updatedFilter.filters[fieldName][operator].queries.forEach((query) => {
          const [key, value] = query;
          updatedQuery[key] = value;
        });
      });
    });
    setFilter(updatedFilter);
    setBundledQuery(updatedQuery);
    setIsStale(false);
  };

  const handleSetFilter = (field, operator, value, value2) => {
    const newFilterObj = Filter.setQuery(field, operator, value, value2);
    handleUpdateFilter(newFilterObj);
  };

  const handleUnsetFilter = (unsetQry) => {
    const newFilterObj = Filter.unsetQuery(unsetQry);
    handleUpdateFilter(newFilterObj);
  };

  const setLimit = (limit) => {
    updateQuery("limit", limit);
  };

  const setPage = (page) => {
    updateQuery("page", page);
  };

  const setQuery = {
    sort: setSort,
    search: setSearch,
    filter: handleSetFilter,
    unsetFilter: handleUnsetFilter,
    limit: setLimit,
    page: setPage,
  };

  return [bundledQuery, isStale, setIsStale, setQuery, Filter];
}

export default useQuery;
