import axios from "axios";

const apiClient = axios.create({
  baseURL: "/api/",
  timeout: 5000,
});

async function request(options, queryClient) {
  const onSuccess = function (res) {
    return options.transformData(res);
  };
  const onError = function (err) {
    if (err.response?.status === 401) {
      queryClient?.invalidateQueries(["currentUser"]);
    }

    let message = "Server Error";
    if (err.response?.status >= 400 && err.response?.status < 500) {
      if (err.response?.data?.message) message = err.response.data?.message;
      if (err.response?.data?.Message) message = err.response.data?.Message;
    }
    if (err.response.status === 404) message = `404: ${message}`;

    return Promise.reject(message);
  };
  return apiClient(options.req).then(onSuccess).catch(onError);
}

export default request;
