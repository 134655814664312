import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useTableContext } from "../TableContext";
import FlexCentered from "../../../Containers/FlexCentered";
import Dropdown from "../../Dropdown";
import P from "../../P";
import classNames from "classnames";

function Pagination() {
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const { api, setQuery } = useTableContext();

  useEffect(() => {
    if (!api.data) return;
    setCurrentPage({ label: `${api.data.page}`, value: api.data.page });
    setRowsPerPage({ label: `${api.data.limit}`, value: api.data.limit });
  }, [api.data]);

  if (!api.data) return;

  const handleSetRowsPerPage = (val) => {
    setQuery.limit(val.value);
  };

  const handleSetPage = (val) => {
    setQuery.page(val.value);
  };

  const rowsPerPageDropdownConfig = {
    data: [
      { label: "25", value: 25 },
      { label: "50", value: 50 },
      { label: "75", value: 75 },
      { label: "100", value: 100 },
    ],
    getKey: (row) => row.label,
    options: {
      disallowEmptyValues: true,
    },
  };

  const pageOptions = [];
  if (Number.isFinite(api.data.maxPages)) {
    for (let i = 1; i <= api.data.maxPages; i++) {
      pageOptions.push({ label: `${i}`, value: i });
    }
  }

  const pageDropdownConfig = {
    data: pageOptions,
    getKey: (row) => row.label,
    options: {
      disallowEmptyValues: true,
    },
  };

  return (
    <div className="bg-gbfBlue-500 mx-2 rounded-b-lg px-2 py-1 text-gbfCream-50">
      <div className="grid grid-cols-3">
        <FlexCentered row className="gap-x-2">
          <P className="whitespace-nowrap">Rows per page</P>
          <Dropdown
            value={rowsPerPage}
            onChange={handleSetRowsPerPage}
            config={rowsPerPageDropdownConfig}
            className="w-[3rem] text-gbfBlue-900 text-center"
          />
        </FlexCentered>

        <FlexCentered row className="gap-x-1">
          <BsChevronLeft
            className={classNames(
              "text-xl mt-1 mr-1 cursor-pointer hover:text-gbfGreen-500",
              {
                "select-none pointer-events-none opacity-50":
                  currentPage?.value === 1,
              }
            )}
            onClick={() => setQuery.page(currentPage?.value - 1)}
          />

          <P className="whitespace-nowrap">Page</P>

          <div>
            <Dropdown
              value={currentPage}
              onChange={handleSetPage}
              config={pageDropdownConfig}
              className="w-[3rem] text-gbfBlue-900 text-center"
            />
          </div>
          <P className="whitespace-nowrap">{`of ${api.data?.maxPages}`}</P>
          <div>
            <BsChevronRight
              className={classNames(
                "text-xl mt-1 ml-1 cursor-pointer hover:text-gbfGreen-500",
                {
                  "select-none pointer-events-none opacity-50":
                    currentPage?.value === api.data?.maxPages,
                }
              )}
              onClick={() => setQuery.page(currentPage?.value + 1)}
            />
          </div>
        </FlexCentered>
      </div>
    </div>
  );
}

export default Pagination;
