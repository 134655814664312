import { useGetAvailableShifts, useGetUserRota } from "../../api";
import EditShiftsView from "./EditShiftsView";
import Provider from "../../providers/ViewAndEditShiftsContext";
import RotaView from "./RotaView";
import Loading from "../../Components/Loading";

function ViewAndEditShifts({ user, isAdmin = false }) {
  const getAvailableShiftsApi = useGetAvailableShifts({
    userRef: user?._id,
  });
  const availableShifts = getAvailableShiftsApi?.data;

  const getUserRotaApi = useGetUserRota({
    userRef: user._id,
  });
  const userRota = getUserRotaApi?.data?.userRota;

  if (
    !availableShifts ||
    getAvailableShiftsApi.isInitialLoading ||
    getUserRotaApi.isInitialLoading
  )
    return <Loading />;

  return (
    <Provider
      user={user}
      availableShifts={availableShifts}
      api={getAvailableShiftsApi}
    >
      <EditShiftsView isAdmin={isAdmin} />
      <RotaView userRota={userRota} api={getUserRotaApi} isAdmin={isAdmin} />
    </Provider>
  );
}
export default ViewAndEditShifts;
