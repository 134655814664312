import { useEditShifts } from "../api";
import { useEffect, useState } from "react";
import { useToastContext } from "../providers/toastContext";
import classNames from "classnames";
import Button from "../Components/Button";
import FlexCentered from "../Containers/FlexCentered";
import P from "../Components/P";
import { useViewAndEditShiftsContext } from "../providers/ViewAndEditShiftsContext";

function AddEditShifts({ isAdmin }) {
  const {
    user,
    selectedTeam,
    selectedJob,
    selectedDay,
    setIsEditShiftsViewOpen,
    setIsLoading,
  } = useViewAndEditShiftsContext();
  const slots = selectedDay?.slots;

  const [selectedShifts, setSelectedShifts] = useState({});
  const [deSelectedShifts, setDeselectedShifts] = useState({});
  const editShiftsApi = useEditShifts();

  useEffect(() => {
    setIsLoading(editShiftsApi.isLoading);
  }, [editShiftsApi.isLoading, setIsLoading]);

  const { createSuccessToast, createErrorToast } = useToastContext();
  useEffect(() => {
    if (editShiftsApi.isSuccess) {
      createSuccessToast(
        `${isAdmin ? "The" : "Success!! Your"} rota has been updated`
      );
      setIsEditShiftsViewOpen(false);
    }
  }, [
    createSuccessToast,
    editShiftsApi.isSuccess,
    isAdmin,
    setIsEditShiftsViewOpen,
  ]);

  useEffect(() => {
    if (editShiftsApi.error) {
      createErrorToast(editShiftsApi.error);
      setSelectedShifts({});
      setDeselectedShifts({});
    }
  }, [createErrorToast, editShiftsApi.error]);

  if (!selectedTeam || !selectedJob || !slots) return;

  const handleSave = () => {
    editShiftsApi.mutate({
      userRef: user._id,
      body: {
        team: selectedTeam.id,
        job: selectedJob.id,
        selectedShifts: Object.keys(selectedShifts),
        deSelectedShifts: Object.keys(deSelectedShifts),
      },
    });
  };

  const labelHeight = 25;
  const slotHeight = 35;

  const earliestStart = new Date(slots[0].start);
  const latestFinish = new Date(slots[slots.length - 1].end);
  const quarters = [];
  let currQuarter = earliestStart;
  while (currQuarter.getTime() <= latestFinish.getTime()) {
    quarters.push(currQuarter);
    currQuarter = new Date(currQuarter.getTime() + 15 * 60 * 1000);
  }
  const timeLinesAndLabels = [];
  for (let i = 0; i < quarters.length; i++) {
    const x = i === 0 ? 0 : (i / (quarters.length - 1)) * 100;
    const time = quarters[i];
    const isHourLine = time.getMinutes() === 0;
    if (isHourLine) {
      timeLinesAndLabels.push(
        <div
          key={`hour-label-${i}`}
          className="-translate-x-1/2 absolute"
          style={{
            left: `${x}%`,
            top: 0,
            bottom: `${labelHeight}px`,
          }}
        >
          {time.toLocaleTimeString("en-GB", { hour: "2-digit" })}
        </div>
      );
    }
    timeLinesAndLabels.push(
      <div
        key={`time-line-${i}`}
        className={classNames("absolute w-[1px] bg-gbfBlue-500 z-20", {
          "opacity-30": !isHourLine,
          "opacity-50": isHourLine,
        })}
        style={{
          left: `${x}%`,
          top: `${labelHeight}px`,
          bottom: 0,
        }}
      />
    );
  }

  const handleClickShift = (shift) => {
    const doSelect = !shift.selected && !selectedShifts[shift._id];
    const doDeselect = shift.selected && !deSelectedShifts[shift._id];

    //Update state

    const updatedSelectedShifts = { ...selectedShifts };
    if (doSelect) {
      updatedSelectedShifts[shift._id] = true;
    } else if (updatedSelectedShifts[shift._id]) {
      delete updatedSelectedShifts[shift._id];
    }
    setSelectedShifts(updatedSelectedShifts);

    const updatedDeSelectedShifts = { ...deSelectedShifts };
    if (doDeselect) {
      updatedDeSelectedShifts[shift._id] = true;
    } else if (updatedDeSelectedShifts[shift._id]) {
      delete updatedDeSelectedShifts[shift._id];
    }
    setDeselectedShifts(updatedDeSelectedShifts);
  };

  const renderedShifts = slots.map((shift, i) => {
    const isOdd = (i + 1) % 2 !== 0;
    const start = new Date(shift.start);
    const leftBlock =
      (start.getTime() - earliestStart.getTime()) / 15 / 60 / 1000;
    const end = new Date(shift.end);
    const rightBlock =
      (end.getTime() - earliestStart.getTime()) / 15 / 60 / 1000;

    const left =
      leftBlock === 0 ? 0 : (leftBlock / (quarters.length - 1)) * 100;
    const right =
      rightBlock === 0 ? 0 : (rightBlock / (quarters.length - 1)) * 100;

    const isPriority =
      !(
        selectedShifts[shift._id] ||
        (shift.selected && !deSelectedShifts[shift._id])
      ) &&
      !(shift.full || shift.incompatible) &&
      !shift.targetMet;

    return (
      <div
        key={shift._id}
        className={classNames(
          "absolute text-center z-30 shadow-sm bg-opacity-80 cursor-pointer select-none hover:scale-105 hover:shadow",
          {
            "bg-gbfGreen-500":
              selectedShifts[shift._id] ||
              (shift.selected && !deSelectedShifts[shift._id]),
            "bg-gray-500":
              !(
                selectedShifts[shift._id] ||
                (shift.selected && !deSelectedShifts[shift._id])
              ) && !(shift.full || shift.incompatible),
            "bg-gbfRed-500":
              !(
                selectedShifts[shift._id] ||
                (shift.selected && !deSelectedShifts[shift._id])
              ) &&
              (shift.full || shift.incompatible),
            "pointer-events-none":
              !(
                selectedShifts[shift._id] ||
                (shift.selected && !deSelectedShifts[shift._id])
              ) &&
              (shift.full || (!isAdmin && shift.incompatible)),
            "border-l-4 border-gbfYellow-500": isPriority,
          }
        )}
        onClick={() => handleClickShift(shift)}
        style={{
          left: `${left}%`,
          width: `${right - left}%`,
          top: isOdd ? `${labelHeight}px` : `${labelHeight + slotHeight}px`,
          height: `${slotHeight}px`,
        }}
      >
        <FlexCentered row className="w-full h-full text-sm lg:text-md">
          {start.toLocaleTimeString("en-GB", {
            timeZone: "Europe/London",
            hour: "2-digit",
            minute: "2-digit",
          })}
          -
          {end.toLocaleTimeString("en-GB", {
            timeZone: "Europe/London",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </FlexCentered>
      </div>
    );
  });

  const isUnsaved =
    Object.keys(selectedShifts).length + Object.keys(deSelectedShifts).length >
    0;

  return (
    <>
      <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-1 items-center mb-4">
        <div className="bg-gbfGreen-500 w-4 h-4" />
        <P>Selected </P>
        <div className="bg-gray-500 w-4 h-4 border-l-2 border-gbfYellow-500" />
        <P>Available (High priority)</P>
        <div className="bg-gray-500 w-4 h-4" />
        <P>Available (Low priority)</P>
        <div className="bg-gbfRed-500 w-4 h-4" />
        <P>Full/Schedule conflict</P>
      </div>

      <div className="w-full  bg-gbfBlue-200 px-[15px]">
        <div
          className="relative w-full"
          style={{
            height: `${labelHeight + slotHeight * 2}px`,
          }}
        >
          {timeLinesAndLabels}
          {renderedShifts}
        </div>
      </div>
      <FlexCentered col className="mt-4">
        {isUnsaved && (
          <div className="bg-gbfRed-700 px-2 py-1 mb-2">
            <P className="text-gbfCream-50">There are unsaved changes</P>
          </div>
        )}

        <div className="grid grid-cols-2 gap-x-2">
          <Button
            className="w-full"
            primary
            onClick={handleSave}
            disabled={!isUnsaved}
            loading={editShiftsApi.isLoading}
          >
            Save
          </Button>
          <Button
            className="w-full"
            secondary
            onClick={() => {
              setSelectedShifts({});
              setDeselectedShifts({});
            }}
            disabled={!isUnsaved}
          >
            Reset
          </Button>
        </div>
      </FlexCentered>
    </>
  );
}
export default AddEditShifts;
