import {
  BiChevronUp,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import FlexCentered from "../../Containers/FlexCentered";

function NavBar({
  y,
  setY,
  x,
  setX,
  blockWidth,
  maxX,
  minX,
  rowHeight,
  maxY,
  bounds,
  width,
}) {
  const xIncrement = Math.ceil(window.innerWidth / 8 / blockWidth) * blockWidth;
  const yIncrement = rowHeight * 5;

  const handleClick = (direction) => {
    if (direction === "right" || direction === "left") {
      let increment = xIncrement;
      if (direction === "left") increment *= -1;
      let newX = Math.max(Math.min(x + increment, maxX), minX);

      setX(newX);
    }
    if (direction === "up" || direction === "down") {
      let increment = yIncrement;
      if (direction === "up") increment *= -1;
      let newY = Math.max(Math.min(y + increment, maxY), 0);

      setY(newY);
    }
  };

  return (
    <>
      {y !== 0 && (
        <div
          style={{
            left: bounds.left,
            right: bounds.right,
            top: bounds.top,
            height: width,
          }}
          className="absolute bg-gbfCream-50 opacity-0 z-50 hover:opacity-100 bg-opacity-40 text-center cursor-pointer"
          onClick={() => handleClick("up")}
        >
          <FlexCentered col className="h-full w-full">
            <BiChevronUp />
          </FlexCentered>
        </div>
      )}
      {y !== maxY && (
        <div
          style={{
            left: bounds.left,
            right: bounds.right,
            bottom: bounds.bottom,
            height: width,
          }}
          className="absolute bg-gbfCream-50 opacity-0 z-50 hover:opacity-100 bg-opacity-40 text-center cursor-pointer"
          onClick={() => handleClick("down")}
        >
          <FlexCentered col className="h-full w-full">
            <BiChevronDown />
          </FlexCentered>
        </div>
      )}
      {x !== minX && (
        <div
          style={{
            left: bounds.left,
            top: bounds.top,
            bottom: bounds.bottom,
            width: width,
          }}
          className="absolute bg-gbfCream-50 opacity-0 z-50 hover:opacity-100 bg-opacity-40 text-center cursor-pointer"
          onClick={() => handleClick("left")}
        >
          <FlexCentered col className="h-full w-full">
            <BiChevronLeft />
          </FlexCentered>
        </div>
      )}
      {x !== maxX && (
        <div
          style={{
            right: bounds.right,
            top: bounds.top,
            bottom: bounds.bottom,
            width: width,
          }}
          className="absolute bg-gbfCream-50 opacity-0 z-50 hover:opacity-100 bg-opacity-40 text-center cursor-pointer"
          onClick={() => handleClick("right")}
        >
          <FlexCentered col className="h-full w-full">
            <BiChevronRight />
          </FlexCentered>
        </div>
      )}
    </>
  );
}
export default NavBar;
