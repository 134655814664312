import classNames from "classnames";
function P({ className, strong, children, ...props }) {
  const classes = classNames(
    "text-small md:text-md max-w-prose",
    {
      "font-semibold": strong,
    },
    className
  );
  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
}

export default P;
