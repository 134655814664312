import { useGetUser } from "../../../api";
import { useParams } from "react-router-dom";
import getApiStatusPage from "../../APIStatusPage";
import Page from "../../../Containers/Page";
import EditUserForm from "../../../forms/users/EditUserForm";
import Heading from "../../../Components/Heading";
import FlexCentered from "../../../Containers/FlexCentered";

function EditUserPage() {
  const { userRef } = useParams();
  const getUserApi = useGetUser({
    userRef,
    params: {
      fields: "fullName,email,userName,password,givenName,familyName",
    },
  });
  const user = getUserApi?.data?.user;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;
  if (!user) return;

  return (
    <Page title={user?.fullName || "user"}>
      <FlexCentered col>
        <Heading primary className="mb-8 mt-12">
          Edit User
        </Heading>
        <EditUserForm user={user} />
      </FlexCentered>
    </Page>
  );
}
export default EditUserPage;
