import Page from "../Containers/Page";
import FlexCentered from "../Containers/FlexCentered";
import Loading from "../Components/Loading";
import Heading from "../Components/Heading";
import Link from "../Components/Link";
import P from "../Components/P";

function getFallBackDetails() {
  let fallback = "public";
  if (window.location.pathname.includes("/admin")) fallback = "admin";
  if (window.location.pathname.includes("/dashboard")) fallback = "user";

  let fallBackLink;
  let fallBackLabel;
  if (fallback === "admin") {
    fallBackLink = "/admin";
    fallBackLabel = "admin dashboard";
  }
  if (fallback === "user") {
    fallBackLink = "/dashboard";
    fallBackLabel = "dashboard";
  }
  if (fallback === "public") {
    fallBackLink = "/";
    fallBackLabel = "home";
  }
  return [fallBackLink, fallBackLabel];
}

function LoadingPage() {
  return (
    <Page title="Loading">
      <FlexCentered col className="pt-12">
        <Loading />
      </FlexCentered>
    </Page>
  );
}

function ErrorPage() {
  const [fallBackLink, fallBackLabel] = getFallBackDetails();

  return (
    <Page title={`Error`}>
      <FlexCentered col className="pt-12">
        <Heading primary className="text-xl text-gbfGreen-900">
          {`Server Error`}
        </Heading>
        <P className="mt-4">
          Sorry, something has gone awry. If the problem persists please contact
          us.
        </P>
        <P className="mt-4">
          Return to{" "}
          <Link to={fallBackLink} className="underline">
            {fallBackLabel}
          </Link>
        </P>
      </FlexCentered>
    </Page>
  );
}

function NotFoundPage({ type }) {
  const [fallBackLink, fallBackLabel] = getFallBackDetails();

  return (
    <Page title={`404 - ${type} not found`}>
      <div className="pt-12 min-h-screen flex flex-col items-center">
        <Heading primary className="text-xl text-gbfGreen-900">
          {`404 - ${type} not found`}
        </Heading>
        <P className="mt-4">
          Return to{" "}
          <Link to={fallBackLink} className="underline">
            {fallBackLabel}
          </Link>
        </P>
      </div>
    </Page>
  );
}

function getApiStatusPage({ apis }) {
  if (
    apis.some(
      (el) => (el.api.isFetching && !el.initialLoadOnly) || !el.api.isFetched
    )
  ) {
    return <LoadingPage />;
  }
  let failedApi = undefined;
  apis.forEach((el) => {
    if (failedApi) return;
    if (el.api?.error) return (failedApi = el);
    if (el.dataRequired && !el.api?.data) return (failedApi = el);
  });

  if (failedApi) {
    if (`${failedApi?.api?.error}`.startsWith("404")) {
      return <NotFoundPage type={failedApi.type} />;
    } else {
      return <ErrorPage />;
    }
  }

  return undefined;
}
export default getApiStatusPage;
