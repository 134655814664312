import Dropdown from "../../Components/Dropdown";
import Label from "../../Components/Label";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import getTeamsInDateRange from "./helpers.js/getTeamsAndJobsInDateRange";
import getDateRangeForTeam from "./helpers.js/getDateRangeForTeam";

function Controls({
  teams,
  selectedTeam,
  setSelectedTeam,
  days,
  earliestDate,
  setEarliestDate,
  latestDate,
  setLatestDate,
}) {
  const earliestDates = [];
  const latestDates = [];
  days.forEach((day) => {
    if (!latestDate || latestDate.earliestStart >= day.earliestStart) {
      earliestDates.push(day);
    }
    if (!earliestDate || earliestDate.earliestStart <= day.earliestStart) {
      latestDates.push(day);
    }
  });

  const validateTeam = (earliestDay, latestDay) => {
    if (!selectedTeam) return;
    const teamsInDateRange = getTeamsInDateRange(days, earliestDay, latestDay);
    if (!teamsInDateRange[selectedTeam._id.toString()]) {
      setSelectedTeam(null);
    }
  };

  const handleSetEarliestDate = (value) => {
    setEarliestDate(value);
    validateTeam(value, latestDate);
  };

  const handleSetLatestDate = (value) => {
    setLatestDate(value);
    validateTeam(earliestDate, value);
  };

  const handleSetTeam = (value) => {
    setSelectedTeam(value);
    if (value) {
      const teamDateRange = getDateRangeForTeam(value, days);
      setEarliestDate(teamDateRange[0]);
      setLatestDate(teamDateRange[1]);
    }
  };

  return (
    <div className="px-2 w-fit">
      <div className="grid grid-cols-[auto_auto] gap-x-4 gap-y-2 w-fit ">
        <Label>Team</Label>
        <Dropdown
          value={selectedTeam}
          onChange={handleSetTeam}
          config={{
            data: teams,
            getLabel: (team) => team.name,
          }}
          placeholder="Team"
        />

        <Label>Range</Label>
        <FlexCentered row className="gap-x-2">
          <Dropdown
            value={earliestDate}
            onChange={handleSetEarliestDate}
            config={{
              data: earliestDates,
              getLabel: (date) => date.day,
              getKey: (date) => date.day,
              options: {
                disallowEmptyValues: true,
              },
            }}
          />

          <Label>To</Label>
          <Dropdown
            value={latestDate}
            onChange={handleSetLatestDate}
            config={{
              data: latestDates,
              getLabel: (date) => date.day,
              getKey: (date) => date.day,
              options: {
                disallowEmptyValues: true,
              },
            }}
          />
        </FlexCentered>
      </div>
    </div>
  );
}
export default Controls;
