import Loading from "../../../../Components/Loading";
import ModalWindow from "../../../../Components/ModalWindow";
import FlexCentered from "../../../../Containers/FlexCentered";
import { useEditRotaContext } from "../../../../providers/EditRotaContext";

function LoadingOverlay() {
  const { isLoading } = useEditRotaContext();
  if (!isLoading) return;

  return (
    <ModalWindow options={{ blur: true }}>
      <FlexCentered col className="h-[100vh] w-full">
        <Loading />
      </FlexCentered>
    </ModalWindow>
  );
}
export default LoadingOverlay;
