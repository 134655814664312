const locations = [
  "African",
  "Alpine",
  "Amazonian",
  `Appleton's`,
  "Bahama",
  `Bailey's`,
  "Bolivian",
  "Brazilian",
  "Cambodian",
  "Canada",
  "Colombian",
  "Congo",
  "Cuban",
  `Darwin's`,
  "Egyptian",
  "European",
  "Eurasian",
  "Galapagos",
  "Greater",
  "Himalayan",
  "Indian",
  "Kentucky",
  "Kenya",
  "Lesser",
  "Madagascan",
  "Mekong",
  "Mexican",
  "Murray's",
  `Oddie's`,
  "Oriental",
  `Packham's`,
  "Pacific",
  "Paradise",
  "Patagonian",
  "Philippine",
  "Yemen",
];

export default locations;
