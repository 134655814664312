import classNames from "classnames";
function Label({ className, children, ...props }) {
  const classes = classNames(
    "font-semibold cursor-pointer tracking-wide",
    className
  );
  return (
    <label className={classes} {...props}>
      {children}
    </label>
  );
}

export default Label;
