import { useCreateRota, useEditRota } from "../../api";
import { useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DevTool } from "@hookform/devtools";
import classNames from "classnames";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Label from "../../Components/Label";
import FlexCentered from "../../Containers/FlexCentered";
import DateInput from "../../Components/DateInput";
import RenderedErrors from "../../Components/RenderedErrors";
import Heading from "../../Components/Heading";
import { useToastContext } from "../../providers/toastContext";

function CreateRotaForm({ rota }) {
  const isEditMode = !!rota;
  const defaults = isEditMode
    ? {
        name: rota.name,
        start: new Date(rota.start),
        end: new Date(rota.end),
        eventStart: new Date(rota.eventStart),
        eventEnd: new Date(rota.eventEnd),
        lockdown: new Date(rota.lockdown),
      }
    : {};

  const createRotaApi = useCreateRota();
  const editRotaApi = useEditRota();

  const { createSuccessToast, createErrorToast } = useToastContext();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (createRotaApi?.isSuccess) {
      createSuccessToast("Rota created successfully");
      navigateTo("/admin/rota");
    }
  }, [createRotaApi?.isSuccess, createSuccessToast, navigateTo]);

  useEffect(() => {
    if (editRotaApi.error) {
      createErrorToast(editRotaApi.error);
    }
  }, [createErrorToast, editRotaApi.error]);

  useEffect(() => {
    if (editRotaApi.isSuccess) {
      createSuccessToast("Rota updated successfully");
      navigateTo("/admin/rota");
    }
  }, [createSuccessToast, editRotaApi.isSuccess, navigateTo]);

  const form = useForm({
    values: defaults,
  });
  const errors = form.formState.errors;
  const { register } = form;
  const nameProps = register("name", {
    required: "Event name required",
  });
  register("start", {
    required: "Earliest shift required",
  });
  register("end", {
    required: "End date required",
    validate: (value, values) => {
      if (new Date(value) < new Date(values.start)) {
        return "Latest shift must postdate the earliest shift";
      }
      return true;
    },
  });
  register("eventStart", {
    required: "Event start required",
    validate: (value, values) => {
      if (new Date(value) < new Date(values.start)) {
        return "Event start cannot predate the earliest shift";
      }
      if (new Date(value) > new Date(values.end)) {
        return "Event start cannot postdate the latest shift";
      }
      return true;
    },
  });

  register("eventEnd", {
    required: "Event end required",
    validate: (value, values) => {
      if (new Date(value) < new Date(values.eventStart)) {
        return "Event end cannot predate event start";
      }
      if (new Date(value) > new Date(values.end)) {
        return "Event end cannot postdate the latest shift";
      }
      return true;
    },
  });

  register("lockdown", {
    required: "Lockdown date required",
    validate: (value, values) => {
      if (new Date(value) > new Date(values.start)) {
        return "Lockdown must predate the start date";
      }
      return true;
    },
  });

  const handleSubmit = (data) => {
    if (isEditMode) {
      editRotaApi.mutate({
        rotaRef: rota._id,
        body: data,
      });
    } else {
      createRotaApi.mutate({
        body: data,
      });
    }
  };

  const startDate = useWatch({
    control: form.control,
    name: "start",
  });
  const endDate = useWatch({
    control: form.control,
    name: "end",
  });
  const eventStart = useWatch({
    control: form.control,
    name: "eventStart",
  });

  useEffect(() => {
    if (!startDate) {
      form.setValue("end", null);
      form.setValue("lockdown", null);
      form.setValue("eventStart", null);
      form.setValue("eventEnd", null);
    }
  }, [form, startDate]);

  return (
    <>
      <form
        className={classNames("pb-56", {
          "pointer-events-none opacity-50":
            createRotaApi.isLoading || editRotaApi.isLoading,
        })}
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
      >
        <FlexCentered col>
          <FlexCentered col>
            <Heading primary className="mb-12">
              Create Rota
            </Heading>
            <Label htmlFor="name" className="mb-2">
              Event Name
            </Label>
            <Input
              type="name"
              id="name"
              className={classNames("w-[20rem] max-w-full py-[0.75rem]")}
              {...nameProps}
              ref={null}
              innerRef={nameProps.ref}
              error={errors.name}
            />
            <div className="w-full h-[1px] bg-gbfBlue-900 my-12" />

            <Label htmlFor="start" className="mb-2">
              Earliest Shift
            </Label>
            <Controller
              control={form.control}
              name="start"
              render={({ field: { onChange, onBlur, value } }) => (
                <DateInput
                  id="start"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  min={new Date(2015, 0, 1)}
                  className="w-[20rem] mb-8"
                  error={errors.start}
                />
              )}
            />
            <Label htmlFor="end" className="mb-2">
              Latest Shift
            </Label>
            <Controller
              control={form.control}
              name="end"
              render={({ field: { onChange, onBlur, value } }) => (
                <DateInput
                  id="end"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  min={new Date(startDate)}
                  max={
                    new Date(
                      new Date(startDate).getTime() + 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  className="w-[20rem]"
                  error={errors.end}
                  disabled={!startDate}
                />
              )}
            />
            <div className="w-full h-[1px] bg-gbfBlue-900 my-12" />
            <Label htmlFor="eventStart" className="mb-2">
              Event Start
            </Label>
            <Controller
              control={form.control}
              name="eventStart"
              render={({ field: { onChange, onBlur, value } }) => (
                <DateInput
                  id="eventStart"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  min={new Date(startDate)}
                  max={new Date(endDate)}
                  className="w-[20rem] mb-2"
                  error={errors.eventStart}
                  disabled={!endDate}
                />
              )}
            />
            <Label htmlFor="eventEnd" className="mb-2">
              Event End
            </Label>
            <Controller
              control={form.control}
              name="eventEnd"
              render={({ field: { onChange, onBlur, value } }) => (
                <DateInput
                  id="eventEnd"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  min={new Date(eventStart)}
                  max={new Date(endDate)}
                  className="w-[20rem]"
                  error={errors.eventEnd}
                  disabled={!eventStart}
                />
              )}
            />
            <div className="w-full h-[1px] bg-gbfBlue-900 my-12" />
            <Label htmlFor="lockdown" className="mb-2">
              Lockdown
            </Label>
            <Controller
              control={form.control}
              name="lockdown"
              render={({ field: { onChange, onBlur, value } }) => (
                <DateInput
                  id="lockdown"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  min={
                    new Date(
                      new Date(startDate).getTime() - 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  max={new Date(new Date(startDate))}
                  className="w-[20rem] mb-12"
                  error={errors.lockdown}
                  disabled={!startDate}
                />
              )}
            />
            <Button primary loading={createRotaApi.isLoading}>
              Save
            </Button>
            <RenderedErrors
              errors={errors}
              apiError={createRotaApi.error}
              className="bg-gbfCream-50"
            />
          </FlexCentered>
        </FlexCentered>
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default CreateRotaForm;
