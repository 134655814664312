import classNames from "classnames";

function FlexCentered({ row, col, className, children }) {
  return (
    <div
      className={classNames(
        "flex",
        {
          "flex-col": col,
          "flex-row": row,
        },
        "items-center justify-center",
        className
      )}
    >
      {children}
    </div>
  );
}

export default FlexCentered;
