function getPublicLinks(currentUser, handleLogOut) {
  if (!currentUser || !currentUser?.isLoggedIn) return [];

  const logOutLink = [
    {
      to: "/log-out",
      onClick: handleLogOut,
      label: "Log Out",
    },
  ];
  return logOutLink;
}

export default getPublicLinks;
