import { Fragment, useState, useRef } from "react";
import Link from "../../../Components/Link";
import classNames from "classnames";
import ModalWindow from "../../../Components/ModalWindow";

function LinkItem({ link }) {
  const [secondaryNavIsOpen, setSecondaryNavIsOpen] = useState(false);
  const linkRef = useRef(null);

  return (
    <Fragment>
      <Link
        to={link.to}
        onClick={link.onClick}
        onMouseOver={() => setSecondaryNavIsOpen(true)}
        innerRef={linkRef}
      >
        {link.label}
      </Link>
      {link.children && secondaryNavIsOpen && (
        <ModalWindow
          options={{
            relativeTo: {
              ref: linkRef.current,
            },
            pos: {
              bottom: 0,
              right: 0,
            },
            onMouseOut: () => setSecondaryNavIsOpen(false),
            onResize: () => setSecondaryNavIsOpen(false),
          }}
        >
          <div className="flex flex-col items-end bg-gbfBlue-700 w-max px-6 py-4">
            {link.children.map((childLink) => {
              return (
                <Link
                  to={childLink.to}
                  onClick={childLink.onClick}
                  className={classNames(
                    "text-gbfCream-50 hover:text-gbfGreen-300"
                  )}
                  key={`${childLink.to}-secondary`}
                >
                  {childLink.label}
                </Link>
              );
            })}
          </div>
        </ModalWindow>
      )}
    </Fragment>
  );
}
export default LinkItem;
