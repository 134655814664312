import CreateRotaForm from "../../../forms/rota/CreateRotaForm";

import Page from "../../../Containers/Page";
function CreateRotaPage() {
  return (
    <Page title="Create Rota">
      <div className="pt-12">
        <CreateRotaForm />
      </div>
    </Page>
  );
}
export default CreateRotaPage;
