import Logo from "./Logo";
import NavLinks from "./NavLinks";

import { useGetCurrentUser } from "../../api";
import Breadcrumbs from "./Breadcrumbs";

function Navigation() {
  const currentUserApi = useGetCurrentUser();
  const currentUser = currentUserApi.data;
  if (!currentUser) return;

  return (
    <div id="header" className="bg-gbfBlue-500">
      <div className="w-full h-[80px]  flex flex-row items-center justify-between px-4">
        <Logo />
        {currentUser.isLoggedIn && <NavLinks />}
      </div>
      <div>
        <Breadcrumbs />
      </div>
    </div>
  );
}

export default Navigation;
