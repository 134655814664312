import RegistrationForm from "../../forms/RegistrationForm";
import Page from "../../Containers/Page";

function Register() {
  return (
    <Page title="Admin - Sign-Up">
      <RegistrationForm />
    </Page>
  );
}

export default Register;
