import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../Containers/Page";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import Link from "../Components/Link";
import P from "../Components/P";

function NotFound({ redirect }) {
  const navigateTo = useNavigate();
  useEffect(() => {
    if (redirect) return navigateTo(redirect);
  }, [navigateTo, redirect]);

  return (
    <Page title="404 Not Found">
      <FlexCentered col className="pt-[5vh]">
        <Heading primary className="mb-8">
          404 - Page not found
        </Heading>
        <P>
          Sorry, this page does not exist; click{" "}
          <Link to="/" className="underline">
            here
          </Link>{" "}
          for the home page
        </P>
      </FlexCentered>
    </Page>
  );
}

export default NotFound;
