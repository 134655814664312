import classNames from "classnames";
import { useEffect, useRef } from "react";

function TimeIncrements({
  width,
  height,
  left,
  range,
  blockWidth,
  isResizing,
}) {
  const canvasRef = useRef(null);

  const dpr = Math.ceil(window.devicePixelRatio);

  useEffect(() => {
    if (!canvasRef.current || isResizing) return;
    const canvas = canvasRef.current;

    canvas.width = width * dpr;
    canvas.height = height * dpr;

    const c = canvas.getContext("2d");
    c.scale(dpr, dpr);

    c.strokeStyle = "rgba(255, 255, 255, 0.2)";
    c.lineWidth = 1;

    const drawLine = (x) => {
      const y = height;
      c.beginPath();
      c.moveTo(x, 0);
      c.lineTo(x, y);

      c.stroke();
      c.closePath();
    };
    for (let i = range[0]; i <= range[1]; i++) {
      const x = blockWidth * i + left;
      drawLine(x);
    }

    //Draw a card for a given label
  }, [blockWidth, dpr, height, isResizing, left, range, width]);

  return (
    <div
      className={classNames(
        "absolute inset-0 pointer-events-none w-full h-full ",
        {
          "opacity-0 ": isResizing,
          "transition-opacity duration-300": !isResizing,
        }
      )}
      id="canvas-wrapper"
    >
      <canvas ref={canvasRef} className="h-full w-full" />
    </div>
  );
}
export default TimeIncrements;
