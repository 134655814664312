const styles = {
  page: {
    fontFamily: "Roboto",
    flexDirection: "column",
    backgroundColor: "#fefefc",
    color: "#252637",
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3d405b",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  body: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    color: "#50536b",
    borderWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
  },
  tableCell: {
    justifyItems: "start",
    alignItems: "start",
    padding: "5px",
  },
};

export default styles;
