const waterNouns = [
  "Avocet",
  "Coot",
  "Duck",
  "Eider",
  "Gadwall",
  "Garganey",
  "Grebe",
  "Goldeneye",
  "Mallard",
  "Merganser",
  "Pintail",
  "Pochard",
  "Rail",
  "Scoter",
  "Shelduck",
  "Shoveler",
  "Swan",
  "Teal",
];

export default waterNouns;
