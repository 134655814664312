import { BsList, BsSortUp, BsSortDown } from "react-icons/bs";
import { useTableContext } from "../TableContext";

function SortIcon({ fieldNames }) {
  const { query, setQuery } = useTableContext();

  if (!fieldNames || !query || !setQuery) return undefined;

  let isCurrentField = fieldNames.every((field) =>
    query?.sort?.includes(field)
  );
  if (query.sort?.split(",").length !== fieldNames.length)
    isCurrentField = false;
  let sortDirection;
  if (isCurrentField) {
    sortDirection = query.sort?.startsWith("-") ? "desc" : "asc";
  }

  let icon = (
    <BsList className="opacity-70 cursor-pointer headerControl text-gbfCream-50" />
  );
  if (sortDirection === "asc")
    icon = (
      <BsSortUp className="cursor-pointer headerControl text-gbfGreen-500" />
    );
  if (sortDirection === "desc")
    icon = (
      <BsSortDown className="cursor-pointer headerControl text-gbfGreen-500" />
    );

  return (
    <button
      className="headerControl "
      onClick={() => setQuery?.sort(fieldNames)}
    >
      {icon}
    </button>
  );
}

export default SortIcon;
