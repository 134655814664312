import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetAllUsers({ params }) {
  const queryApi = useQuery({
    queryKey: ["users"],
    queryFn: async () =>
      request({
        req: {
          url: "/admin/users",
          method: "GET",
          params,
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetUser({ userRef, params }) {
  const queryApi = useQuery({
    queryKey: ["user"],
    queryFn: async () =>
      request({
        req: {
          url: `/admin/users/${userRef}`,
          method: "GET",
          params,
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useGetUserCredentials({ userRef, params, enabled }) {
  const queryApi = useQuery({
    queryKey: [`credentials-${userRef}`],
    enabled,
    queryFn: async () =>
      request({
        req: {
          url: `/admin/users/${userRef}/credentials`,
          method: "GET",
          params,
        },
        transformData: (res) => res?.data?.data,
      }),
  });

  return queryApi;
}

function useEditUser() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ userRef, body }) =>
      request({
        req: {
          url: `/admin/users/${userRef}`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`users`]);
    },
  });
  return queryApi;
}

function useUpdateMe() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request({
        req: {
          url: `/admin/users/updateMe`,
          method: "PATCH",
          data: body,
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`currentUser`]);
    },
  });
  return queryApi;
}

function useDeleteUser() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ userRef }) =>
      request({
        req: {
          url: `/admin/users/${userRef}`,
          method: "DELETE",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`users`]);
    },
  });
  return queryApi;
}

function useMarkCredentialsAsCommunicated() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ userRef }) =>
      request({
        req: {
          url: `/admin/users/${userRef}/credentials-communicated`,
          method: "POST",
        },
        transformData: (res) => res?.data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([`users`]);
      queryClient.invalidateQueries([`user`]);
    },
  });
  return queryApi;
}

export {
  useGetAllUsers,
  useGetUser,
  useGetUserCredentials,
  useMarkCredentialsAsCommunicated,
  useEditUser,
  useDeleteUser,
  useUpdateMe,
};
