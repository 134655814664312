import ForgotPasswordForm from "../../forms/ForgotPasswordForm";
import Page from "../../Containers/Page";

function ForgotPassword() {
  return (
    <Page title="Forgotten Password">
      <ForgotPasswordForm />
    </Page>
  );
}

export default ForgotPassword;
