import { useState } from "react";
import { BsFiletypeCsv } from "react-icons/bs";
import DownloadButton from "../../../DownloadButton";
import { useTableContext } from "../../TableContext";

function CSVIcon() {
  const { url, query, visibleColumns } = useTableContext();
  const columns = [];
  visibleColumns.forEach((col) => {
    col.fieldNames.forEach((key, i) => {
      columns.push(`${key}:${col.fieldNamesLabels[i]}`);
    });
  });

  return (
    <DownloadButton
      className="cursor-pointer hover:text-gbfGreen-800 border-0 px-0 py-0"
      fileType="csv"
      fileName={url}
      url={url}
      params={{ ...query, toCSV: true, columns, completeList: true }}
      showSuccessToast
    >
      <BsFiletypeCsv />
    </DownloadButton>
  );
}
export default CSVIcon;
