import { useGetCurrentUser } from "../api/index";
import { Routes, Route } from "react-router-dom";
import Navigation from "../sections/Navigation";
import UserLogInPage from "../Pages/UserLogInPage";
import UserDashboardPage from "../Pages/Volunteer/UserDashboardPage";

function AdminRouter() {
  const currentUserAPI = useGetCurrentUser();

  const currentUser = currentUserAPI.data;
  if (!currentUser) return <div>Loading</div>;

  if (!currentUser.isLoggedIn || currentUser.isAdmin) return <UserLogInPage />;

  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<UserDashboardPage />} />
      </Routes>
    </>
  );
}

export default AdminRouter;
