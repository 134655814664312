import Controls from "./Controls";
import DayNav from "./DayNav";
import NavBar from "./NavBar";
import Slots from "./Slots";
import getTeamAndJobsList from "./getTeamAndJobsList";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import calculateVisibleBlocks from "./helpers.js/calculateVisibleBlocks";
import calculateMaxY from "./helpers.js/calculateMaxY";
import calculateMaxAndMinX from "./helpers.js/calculateMaxAndMinX";
import SlotPopUp from "./SlotPopUp";
import TimeIncrements from "./TimeIncrements";

function RotaView({ rota }) {
  const [activeSlot, setActiveSlot] = useState(null);

  const defaultEarliestDate = useMemo(() => {
    if (!rota.eventStart || !rota.days) return undefined;
    return rota.days.find((day) => day.day === rota.eventStart);
  }, [rota]);
  const defaultLatestDate = useMemo(() => {
    if (!rota.eventEnd || !rota.days) return undefined;
    return rota.days.find((day) => day.day === rota.eventEnd);
  }, [rota]);

  const [isResizing, setIsResizing] = useState(null);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (!isResizing) setIsResizing(true);
      if (currentTimeout) clearTimeout(currentTimeout);
      setCurrentTimeout(setTimeout(() => setIsResizing(false), 50));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [currentTimeout, isResizing]);

  //Filters
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [earliestDate, setEarliestDate] = useState(
    defaultEarliestDate || rota?.days[0]
  );
  const [latestDate, setLatestDate] = useState(
    defaultLatestDate || rota?.days[rota.days.length - 1]
  );

  //Dimensions
  const [wrapperHeight, setWrapperHeight] = useState("80vh");
  const rowHeight = 40;
  const sideBarWidth = 210;
  const topBarHeight = 75;
  const blockWidth = 20;

  //Position
  const [x, setX] = useState(
    earliestDate ? (earliestDate.earliestBlock - 1) * blockWidth : 0
  );
  const [y, setY] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [minX, setMinX] = useState(0);
  const [maxY, setMaxY] = useState(0);

  //Visible Days
  const [visibleBlockRange, setVisibleBlockRange] = useState(
    calculateVisibleBlocks(
      x,
      sideBarWidth,
      blockWidth,
      earliestDate,
      latestDate
    )
  );

  //Get array of job elements and a row index to give the row of each rendered
  //job element
  const [jobList, rowIndex] = getTeamAndJobsList({
    rota,
    rowHeight,
    visibleBlockRange,
    latestDate,
    selectedTeam,
  });

  //Update wrapper
  const wrapper = useRef();
  const updateWrapperHeight = useCallback(() => {
    if (!wrapper?.current) return;

    //Set wrapper height
    const { top } = wrapper.current.getBoundingClientRect();
    const bottom = window.innerHeight;
    const wrapperHeight = bottom - top;
    const slotAreaHeight = wrapperHeight - topBarHeight;
    setWrapperHeight(`${wrapperHeight}px`);

    //Calculate y range
    const tempMaxY = calculateMaxY(rowIndex, rowHeight, slotAreaHeight);
    setMaxY(tempMaxY);

    // calculate x range
    const [tempMinX, tempMaxX] = calculateMaxAndMinX(
      earliestDate,
      latestDate,
      blockWidth,
      sideBarWidth
    );
    setMaxX(tempMaxX);
    setMinX(tempMinX);

    //If x or y are outside of ranges - Reset them
    let tempX = x;
    if (y > tempMaxY) setMaxY(tempMaxY);
    if (x < tempMinX) {
      setX(tempMinX);
      tempX = tempMinX;
    }
    if (x > tempMaxX) {
      setX(tempMaxX);
      tempX = tempMaxX;
    }

    //Get new visible block range
    const tempMaxVisibleBlockRange = calculateVisibleBlocks(
      tempX,
      sideBarWidth,
      blockWidth,
      earliestDate,
      latestDate
    );

    //If visible block range updated, reset it
    if (
      tempMaxVisibleBlockRange[0] !== visibleBlockRange[0] ||
      tempMaxVisibleBlockRange[1] !== visibleBlockRange[1]
    ) {
      setVisibleBlockRange(tempMaxVisibleBlockRange);
    }
  }, [earliestDate, latestDate, rowIndex, visibleBlockRange, x, y]);

  //Call update wrapper height on load and when dependencies updated
  useEffect(() => {
    updateWrapperHeight();
  }, [updateWrapperHeight]);

  //call update wrapper height when window resized
  useEffect(() => {
    const updateDimensions = () => {
      updateWrapperHeight();
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });
  if (!rota.slots || rota.slots.length === 0)
    return <div>No shifts to display</div>;

  return (
    <>
      <div className="bg-gbfGreen-500 w-[100vw] max-w-full overflow-hidden p-2">
        <Controls
          teams={rota.teams}
          days={rota.days}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          earliestDate={earliestDate}
          latestDate={latestDate}
          setEarliestDate={setEarliestDate}
          setLatestDate={setLatestDate}
        />
      </div>
      <div className="w-[100vw] overflow-hidden" ref={wrapper}>
        <div
          className="relative w-full bg-gbfBlue-900"
          style={{ height: wrapperHeight }}
          onClick={(e) => {
            const id = e.target?.dataset?.id;
            if (!id) setActiveSlot(null);
          }}
        >
          <div
            className="absolute bg-inherit top-0 left-0 z-50"
            style={{
              width: `${sideBarWidth - 25}px`,
              height: `${topBarHeight}px`,
            }}
          />
          <div
            className="absolute w-full text-gbfCream-50 z-20"
            style={{
              bottom: 0,
              top: 0,
              left: `${sideBarWidth - x}px`,
              right: 0,
            }}
          >
            <DayNav
              rota={rota}
              blockWidth={blockWidth}
              topBarHeight={topBarHeight}
              visibleBlockRange={visibleBlockRange}
            />
          </div>
          <div
            className="absolute z-20 overflow-hidden"
            style={{
              left: `${sideBarWidth}px`,
              top: `${topBarHeight}px`,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              className="absolute h-full"
              id="shift-wrapper"
              style={{
                top: `${-y}px`,
                left: `${-x}px`,
                width: `${(latestDate.lastBlock + 1) * blockWidth}px`,
              }}
            >
              <Slots
                rota={rota}
                rowIndex={rowIndex}
                rowHeight={rowHeight}
                blockWidth={blockWidth}
                visibleBlockRange={visibleBlockRange}
                activeSlot={activeSlot}
                setActiveSlot={setActiveSlot}
              />
            </div>
            <SlotPopUp activeSlot={activeSlot} rota={rota} />
          </div>
          <div
            className="absolute z-20 overflow-hidden pointer-events-none"
            style={{
              left: `${sideBarWidth}px`,
              top: `${topBarHeight}px`,
              right: 0,
              bottom: 0,
            }}
          >
            <TimeIncrements
              height={Number(wrapperHeight.replace("px", ""))}
              width={window.innerWidth - sideBarWidth}
              left={-x}
              range={visibleBlockRange}
              blockWidth={blockWidth}
              isResizing={isResizing}
            />
          </div>

          <div
            className="absolute h-full z-30 overflow-hidden"
            style={{ width: `${sideBarWidth}px`, top: `${topBarHeight}px` }}
          >
            <div
              className="absolute  h-full z-30"
              style={{ left: 0, right: 0, top: `${-y}px` }}
            >
              {jobList}
            </div>
          </div>
          <div
            className="absolute bg-gbfBlue-500 h-full opacity-20"
            style={{
              left: `${sideBarWidth}px`,
              top: `${topBarHeight}px`,
              right: 0,
            }}
          />
          <NavBar
            y={y}
            setY={setY}
            maxY={maxY}
            minX={minX}
            x={x}
            setX={setX}
            maxX={maxX}
            blockWidth={blockWidth}
            rowHeight={rowHeight}
            bounds={{
              top: `${topBarHeight}px`,
              right: 0,
              bottom: 0,
              left: `${sideBarWidth}px`,
            }}
            width="35px"
          />
        </div>
      </div>
    </>
  );
}
export default RotaView;
